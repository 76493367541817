import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "videoplayer";
class VideoPlayerService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getVideoSource(folder: string, fileName: string) {
    return this.getAll<ArrayBuffer>(`${CONTROLLER}/videosource`, {
      params: { folder: folder, fileName: fileName },
      responseType: "arraybuffer",
    });
  }
}

export const videoPlayerService = new VideoPlayerService();

<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('handover.Title')"
        :is-parent-element="true"
      ></title-bar>
    </v-col>
    <v-row no-gutters style="gap: 24px">
      <select-row
        v-model="selectedDepartment"
        inTitle=""
        :inItems="departments"
        hideTitle
        :in-place-holder="$tc('handover.SelectDepartment')"
        return-object
        item-text="denominazione"
        item-value="codreparto"
        :width="!isDesktop ? '350px' : null"
      ></select-row>
      <input-center
        :width="isDesktop ? '426px' : '326px'"
        v-model="location"
        :inPlaceHolder="$tc('handover.InsertLocation')"
      ></input-center>
    </v-row>

    <v-row>
      <v-col style="width: 270px">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="handleInsertDialog"
        >
          {{ $tc("general.Insert") }}
        </v-btn>
      </v-col>
      <v-col style="width: 270px">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="handleSearch"
        >
          {{ $tc("general.search") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      v-if="handoverLog.length"
      :headers="headers"
      :items="handoverLog"
      :class="{
        'data-table': isDesktop,
        'data-table-fullwidth': !isDesktop,
      }"
      disable-filtering
      disable-sort
    ></v-data-table>

    <insert-handover-dialog
      v-if="showInsertDialog"
      v-model="showInsertDialog"
      :departments="departments"
      :selected-department.sync="selectedDepartment"
      :location.sync="location"
      @click:close="handleInsertDialog"
      @saveNotes="saveNotes"
    ></insert-handover-dialog>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { handoverService } from "@/services/api/handover.service";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import InsertHandoverDialog from "@/components/Handover/InsertHandoverDialog.vue";
import {
  HandoverLog,
  SaveOrUpdateHandoverNotesRequest,
} from "@/models/entities/Handover/handover.interface";
import { handoverHeaders } from "@/helpers/handover-helper";

const snackbarModule = getModule(SnackbarModule, store);

@Component({
  components: { InsertHandoverDialog },
})
export default class Handover extends Vue {
  departments: DepartmentItemInterface[] = [];
  selectedDepartment: DepartmentItemInterface = null;
  showInsertDialog = false;
  location: string = null;
  handoverLog: HandoverLog[] = [];
  headers = handoverHeaders;

  get isDesktop(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  created(): void {
    this.loadDepartments();
  }

  async loadDepartments(): Promise<void> {
    const response = await handoverService.getDepartmentsByUser();
    if (response.data.length === 1) {
      this.selectedDepartment = response.data[0];
    }

    this.departments = response.data;
  }

  async handleSearch(): Promise<void> {
    if (!this.selectedDepartment) {
      snackbarModule.appearSnackbar({
        message: this.$t("handover.MissingDepartment"),
        type: "error",
      });
      return;
    }

    const response = await handoverService.getHandoverLog(
      this.selectedDepartment.codreparto,
      this.location
    );
    if (!response.data.length) {
      snackbarModule.appearSnackbar({
        message: this.$t("handover.NoResults"),
        type: "warning",
      });
      return;
    }
    this.handoverLog = response.data;
  }

  handleInsertDialog(): void {
    this.showInsertDialog = !this.showInsertDialog;
  }

  async saveNotes(notes: string): Promise<void> {
    const request: SaveOrUpdateHandoverNotesRequest = {
      departmentId: this.selectedDepartment.codreparto,
      location: this.location,
      notes: notes,
    };

    await handoverService.modifyNotes(request);

    this.handleInsertDialog();
    snackbarModule.appearSnackbar({
      message: this.$t("handover.SuccessfullInsertNotes"),
      type: "success",
    });
  }
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 90%;
}

.data-table-fullwidth {
  width: 100%;
}
</style>

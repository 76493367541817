<template>
  <div>
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('maintenance.title')"
        :is-parent-element="true"
      ></title-bar>
    </v-col>
    <v-row v-if="buttonListEnabled.length">
      <div
        :class="
          isOrientationPortrait
            ? 'button-grid-vertical'
            : 'button-grid-horizontal'
        "
      >
        <div
          v-for="btn in buttonListEnabled"
          :key="btn.id"
          class="grid-item"
          @click="goToPage(btn.r, btn.id)"
        >
          <img
            alt="icon"
            style="width: 100%"
            :src="
              btn.id == 'SMALLMNT'
                ? require('@/assets/PIANO DI PICCOLA MANUTENZIONE.png')
                : btn.id == 'MANMNT'
                ? require('@/assets/MANUALISTICA.png')
                : ''
            "
          />
          <span class="grid-item-text">{{ $tc(btn.t) }}</span>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { HomeButton } from "@/models/entities/components/components.interface";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { buttonSortService } from "@/services/api/buttonSort.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component({})
export default class Maintenance extends Vue {
  buttonListEnabled: HomeButton[] = [];
  btnPosition = "110px";

  buttonList = [
    {
      id: "SMALLMNT",
      r: "maintenance/smallMaintenance",
      t: "home.buttonSMALLMNT",
      c: "mod--color_btn_equipment",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SMALLMNT";
        }).length > 0,
      sort: 0,
    },
    {
      id: "MANMNT",
      r: "maintenance/manuals",
      t: "home.buttonMANMNT",
      c: "mod--color_btn_mp",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "MANMNT";
        }).length > 0,
      sort: 0,
    },
  ];

  get isOrientationPortrait() {
    const orientation = window.screen.orientation.type;
    return orientation === "portrait-primary";
  }

  goToPage(path: string, id: string) {
    if (id != "SAP") {
      this.$router.push({ path: path });
    } else {
      window.open(path);
    }
  }

  async created() {
    overlayModule.showOverlay();
    const response = await buttonSortService.getAuthsByParent("MNT");

    this.buttonListEnabled = this.buttonList.filter((value) =>
      response.data.includes(value.id)
    );
    overlayModule.hideOverlay();
  }
}
</script>

<style scoped>
.button-grid-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.button-grid-vertical {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-item {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.grid-item-text {
  font-weight: bold;
  text-align: center;
}
</style>

<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar :title="$tc('stt.title')"></title-bar>
    </v-col>
    <v-col style="width: 450px">
      <input-center
        :disabled.sync="disableMSAField"
        inPlaceHolder="INSERISCI CODICE MSA"
        v-model="MSACode"
        @submitSearch="submitSearch"
      ></input-center>
    </v-col>
    <v-col cols="auto" style="width: 450px">
      <input-center
        :disabled.sync="disableSAPField"
        inPlaceHolder="INSERISCI CODICE SAP"
        v-model="SAPCode"
        type="number"
        @submitSearch="submitSearch"
      ></input-center>
    </v-col>
    <v-col style="width: 270px">
      <v-btn
        class="mod--btn flex-grow-1"
        style="width: 100%"
        x-large
        @click="submitSearch"
      >
        {{ $tc("general.search") }}
      </v-btn>
    </v-col>
    <v-data-table
      v-if="tableDrawItems.length"
      :headers="tableDrawHeaders"
      :items="tableDrawItems"
      disable-pagination
      class="data-table elevation-1 header-bold"
      hide-default-footer
      @click:row="clickRow"
    >
      <template v-slot:[`item.sapCode`]="{ item }">
        <td class="sap-code-column">{{ item.sapCode }}</td>
      </template>
      <template v-slot:[`item.msaCode`]="{ item }">
        <td class="msa-code-column">{{ item.msaCode }}</td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text v-if="item" class="mod--btn_text">
          <v-icon left color="primary" class="ml-1" :size="38">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { msaService } from "@/services/api/msa.service";
import { authRequestService } from "@/services/api/authRequest.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { sttService } from "@/services/api/stt.service";
import { MSAFile } from "@/models/entities/fileFormats/fileFormats.interface";
import { DataTableHeader } from "vuetify";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Stt extends Vue {
  MSACode = "";
  SAPCode = "";
  tableDrawItems: MSAFile[] = [];
  tableDrawHeaders: DataTableHeader[] = [
    {
      text: this.$tc("stt.document"),
      align: "start",
      sortable: false,
      value: "sapCode",
      width: "50vw",
    },
    {
      text: this.$tc("stt.codeMSA"),
      align: "start",
      sortable: false,
      value: "msaCode",
      width: "30vw",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  get disableMSAField(): boolean {
    return this.SAPCode.length > 0;
  }

  get disableSAPField(): boolean {
    return this.MSACode.length > 0;
  }

  async submitSearch(): Promise<void> {
    if (this.SAPCode) {
      overlayModule.showOverlay();
      try {
        const response = await sttService.getPdfNames(this.SAPCode);
        this.tableDrawItems = response.data;
        overlayModule.hideOverlay();
      } catch (e) {
        this.tableDrawItems = [];
        overlayModule.hideOverlay();
      }
    } else {
      if (this.MSACode) {
        overlayModule.showOverlay();
        try {
          const response = await sttService.getSAPCode(this.MSACode);
          this.tableDrawItems = response.data;
          overlayModule.hideOverlay();
        } catch (e) {
          this.tableDrawItems = [];
          overlayModule.hideOverlay();
        }
      } else {
        this.tableDrawItems = [];
        snackbarModule.appearSnackbar({
          message: this.$tc("stt.snackNoMSA"),
          type: "warning",
        });
      }
    }
  }

  clickRow(row: MSAFile): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.sapCode, type: "STT" },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>

<style scoped>
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}

.data-table {
  width: 100%;
  margin-top: 12px;
}

.sap-code-column {
  max-width: 50vw;
  white-space: normal;
  overflow-wrap: break-word;
}

.msa-code-column {
  max-width: 30vw;
  white-space: normal;
  overflow-wrap: break-word;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

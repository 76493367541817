<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar :title="$tc('equipment.title')"></title-bar>
    </v-col>
    <v-col style="width: 450px">
      <input-center
        :inTitle="$tc('equipment.labelEquipment')"
        :inPlaceHolder="$tc('equipment.addCode')"
        v-model="equipmentCode"
        id="input_centre"
        @submitSearch="submitSearch"
      ></input-center>
    </v-col>
    <v-col style="width: 270px">
      <v-btn
        class="mod--btn flex-grow-1"
        style="width: 100%"
        x-large
        @click="submitSearch"
      >
        {{ $tc("general.search") }}
      </v-btn>
    </v-col>
    <input-row
      v-if="tableDraw.items.length > 0 && equipmentLocation"
      :inTitle="$tc('equipment.labelLocation')"
      v-model="equipmentLocation"
      inIsReadonly
    ></input-row>
    <v-data-table
      v-if="tableDraw.items.length"
      id="equipment-table"
      :headers="tableDraw.headers"
      :items="tableDraw.items"
      class="data-table background-header elevation-1 header-bold"
      disable-pagination
      hide-default-footer
      fixed-header
      @click:row="clickRow"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :key="item.name"
          text
          v-if="item.name"
          target="_blank"
          class="mod--btn_text"
        >
          <v-icon left color="primary" class="ml-1" :size="38">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { equipmentService } from "@/services/api/equipment.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { EquipmentFile } from "@/models/entities/fileFormats/fileFormats.interface";
import { DataTableHeader } from "vuetify";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class EquipmentPage extends Vue {
  equipmentCode = "";
  equipmentLocation = "";
  tableDrawItems: EquipmentFile[] = [];
  tableDraw: { headers: DataTableHeader[]; items: EquipmentFile[] } = {
    headers: [
      {
        text: this.$tc("equipment.tableHeadDataSheet"),
        align: "start",
        sortable: false,
        value: "name",
        width: "40vw",
      },
      {
        text: "Ubicazione",
        align: "start",
        sortable: false,
        value: "location",
      },
      {
        text: this.$tc("mp.position"),
        align: "start",
        sortable: false,
        value: "position",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
    items: this.tableDrawItems,
  };

  async submitSearch(): Promise<void> {
    if (this.equipmentCode.length > 0) {
      overlayModule.showOverlay();
      const response = await equipmentService.getFile(this.equipmentCode);

      this.tableDraw.items = response.data;

      overlayModule.hideOverlay();
      this.submitSearchCode();
    } else {
      snackbarModule.appearSnackbar({
        message: this.$tc("equipment.snackNoCode"),
        type: "warning",
      });
      this.tableDraw.items = [];
    }
  }

  async submitSearchCode(): Promise<void> {
    overlayModule.showOverlay();
    const response = await equipmentService.getEquipmentLocation(
      this.equipmentCode
    );

    this.equipmentLocation = response.data;
    overlayModule.hideOverlay();
  }

  clickRow(row: any) {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.name, type: "Equipment" },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>
<style scoped>
.background-header .v-data-table__wrapper > table > thead > tr > th {
  background: #d4dbf9 !important;
  font-weight: bold !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  margin-top: 12px;
}

.data-table > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 40vw;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <div>
    <v-row>
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('products.title')"
          :hideTitile="!btnVisible"
        ></title-bar>
      </v-col>
    </v-row>
    <select-row
      :class="!btnVisible && 'pt-12'"
      v-model="suppliersListDef"
      :inTitle="$tc('products.inputSuppliers')"
      :in-place-holder="$tc('products.inputSuppliers')"
      :inItems="suppliersList"
      hideTitle
      @inChanged="updateProducts"
    ></select-row>
    <select-row
      v-model="productsListDef"
      :inTitle="$tc('products.inputProducts')"
      :in-place-holder="$tc('products.inputProducts')"
      :inItems="productsList"
      hideTitle
      @inChanged="updateCheckboxes"
    ></select-row>
    <v-row v-if="!btnVisible" justify="center">
      <v-col style="max-width: 450px" cols="auto">
        <v-row align="center" justify="space-between">
          <v-col cols="auto" v-if="sheetObject.dataSheet.hasSheet">
            <check-row
              inTitle="ST"
              v-model="sheetObject.dataSheet.value"
              isCenterd
              removeSpacer
              width="300px"
            ></check-row>
          </v-col>
          <v-col cols="auto" v-if="sheetObject.safetySheet.hasSheet">
            <check-row
              inTitle="SDS"
              v-model="sheetObject.safetySheet.value"
              isCenterd
              removeSpacer
              width="300px"
            ></check-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="auto" v-if="sheetObject.dataSheet.hasSheet">
        <check-row
          :inTitle="$tc('products.checkDataSheet')"
          v-model="sheetObject.dataSheet.value"
          isCenterd
        ></check-row>
      </v-col>
      <v-col cols="auto" v-if="sheetObject.safetySheet.hasSheet">
        <check-row
          :inTitle="$tc('products.checkSafetySheet')"
          v-model="sheetObject.safetySheet.value"
          isCenterd
        ></check-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="btnVisible">
      <v-col style="max-width: 450px" cols="auto">
        <v-btn
          class="mod--btn mod--btn_block"
          @click="submitSearch"
          v-if="
            suppliersListDef != '' &&
            productsListDef > 0 &&
            (sheetObject.dataSheet.value || sheetObject.safetySheet.value)
          "
        >
          {{ $tc("general.search") }}
        </v-btn>
        <p v-else class="pt-4 pb-3 mb-0">
          <strong
            v-if="
              this.suppliersListDef != '' &&
              this.productsListDef > 0 &&
              !this.sheetObject.safetySheet.hasSheet &&
              !this.sheetObject.safetySheet.value
            "
          >
            {{ $tc("products.noProduct") }}
          </strong>
          <strong v-else>
            {{ $tc("products.formInvalid") }}
          </strong>
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="px-0">
        <v-data-table
          v-if="tableDraw.items && tableDraw.items.length > 0"
          :headers="tableDraw.headers"
          :items="tableDraw.items"
          disable-pagination
          class="elevation-1 header-bold brack-world-custom"
          hide-default-footer
          :height="btnVisible ? 'calc(100vh - 500px)' : 'calc(100vh - 500px)'"
          @click:row="clickRow"
        >
          <template v-slot:[`item.language`]="{ item }">
            <div class="d-flex">
              <span v-if="item.sheetYearIt > 0">
                {{ $tc("general.langIT") }}
              </span>

              <span v-if="item.sheetYearEn > 0">
                {{ $tc("general.langEN") }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn class="mod--btn_text" text v-if="item.sheetYearIt > 0">
                <v-icon left color="primary" class="mr-3" :size="38">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              <v-btn class="mod--btn_text" text v-if="item.sheetYearEn > 0">
                <v-icon left color="primary" class="mr-3" :size="38">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <p v-else-if="oldSearch.productId > 0" class="mt-5 text-center">
          <strong>
            {{ $tc("products.noProduct") }}
          </strong>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { productService } from "@/services/api/product.service";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import store from "@/store";
import {
  ProductItemElabbed,
  ProductSearchItem,
} from "@/models/entities/product/product.interface";
import { SelectItemNumeric } from "@/models/entities/components/components.interface";
import { fileLogService } from "@/services/api/fileLog.service";
import { fileOpen } from "@/helpers/fileOpen";
const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class ProductsPage extends Vue {
  private sheetObject = {
    dataSheet: {
      value: false,
      hasSheet: false,
    },
    safetySheet: {
      value: false,
      hasSheet: false,
    },
  };
  private suppliersList: string[] = [];
  private suppliersListDef =
    this.suppliersList.length > 0 ? this.suppliersList[0] : "";
  private productsList: SelectItemNumeric[] = [{ value: -1, text: "" }];
  private productsListDef: number = this.productsList[0].value;

  private windowScroll = 0;
  private btnVisible = true;

  private oldSearch: ProductSearchItem = {
    supplier: "",
    productId: -1,
    withSt: false,
    withSds: false,
  };
  private isFormValid = false;

  private tableDraw = {
    headers: [
      {
        text: this.$tc("products.tableHeadName"),
        align: "start",
        sortable: false,
        value: "productName",
      },
      {
        text: this.$tc("products.tableHeadSheetType"),
        align: "start",
        sortable: false,
        value: "sheetType",
      },
      {
        text: this.$tc("products.tableHeadLanguage"),
        align: "start",
        sortable: false,
        value: "language",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
    items: [] as ProductItemElabbed[],
  };

  private created(): void {
    overlayModule.showOverlay();
    window.addEventListener("scroll", this.handleScroll);
    productService
      .getFiltersSuppliers()
      .then((res) => {
        this.suppliersList = res.data;
        overlayModule.hideOverlay();
      })
      .catch(() => {
        overlayModule.hideOverlay();
      });
  }

  private updateProducts(): void {
    overlayModule.showOverlay();
    this.sheetObject.dataSheet.value = false;
    this.sheetObject.safetySheet.value = false;
    this.oldSearch = {
      supplier: "",
      productId: -1,
      withSt: false,
      withSds: false,
    };
    this.productsListDef = -1;
    this.tableDraw.items = null;
    this.sheetObject.safetySheet.hasSheet = null;
    this.sheetObject.dataSheet.hasSheet = null;
    this.btnVisible = true;
    productService
      .getFiltersProducts(this.suppliersListDef)
      .then((res) => {
        let newProductList: SelectItemNumeric[] = [];
        res.data.forEach((item) =>
          newProductList.push({ value: item.productId, text: item.productName })
        );

        this.productsList = newProductList;
        overlayModule.hideOverlay();
      })
      .catch(() => {
        overlayModule.hideOverlay();
      });
  }

  private updateCheckboxes(): void {
    overlayModule.showOverlay();
    productService
      .getChecksFilters({
        productId: this.productsListDef,
        supplier: this.suppliersListDef,
      })
      .then((res) => {
        this.sheetObject.safetySheet.hasSheet = res.data.hasSDS;
        this.sheetObject.dataSheet.hasSheet = res.data.hasST;
        this.sheetObject.dataSheet.value = false;
        this.sheetObject.safetySheet.value = false;
        overlayModule.hideOverlay();
      })
      .catch(() => {
        overlayModule.hideOverlay();
      });
  }

  private submitSearch(): void {
    overlayModule.showOverlay();
    const searchItem = {
      supplier: this.suppliersListDef,
      productId: this.productsListDef,
      withSt: this.sheetObject.dataSheet.value,
      withSds: this.sheetObject.safetySheet.value,
    };
    productService
      .getProducts(searchItem)
      .then((res) => {
        this.tableDraw.items = res.data;
        this.oldSearch = searchItem;
        overlayModule.hideOverlay();
      })
      .catch(() => {
        overlayModule.hideOverlay();
      });
  }

  private handleScroll(event) {
    var ris = this.windowScroll - window.top.scrollY;
    if (
      (ris > 260 &&
        this.sheetObject.dataSheet.hasSheet &&
        this.sheetObject.safetySheet.hasSheet) ||
      (ris > 260 &&
        !this.sheetObject.dataSheet.hasSheet &&
        this.sheetObject.safetySheet.hasSheet) ||
      (ris > 260 &&
        this.sheetObject.dataSheet.hasSheet &&
        !this.sheetObject.safetySheet.hasSheet)
    ) {
      this.btnVisible = true;
      this.windowScroll = window.top.scrollY;
    }
    if (
      (window.top.scrollY - this.windowScroll > 260 &&
        this.sheetObject.dataSheet.hasSheet &&
        this.sheetObject.safetySheet.hasSheet) ||
      (window.top.scrollY - this.windowScroll > 260 &&
        !this.sheetObject.dataSheet.hasSheet &&
        this.sheetObject.safetySheet.hasSheet) ||
      (window.top.scrollY - this.windowScroll > 260 &&
        this.sheetObject.dataSheet.hasSheet &&
        !this.sheetObject.safetySheet.hasSheet)
    ) {
      this.windowScroll = window.top.scrollY;
      this.btnVisible = false;
    }
  }

  private openProductFile(
    sheetType: string,
    lang: string,
    productId: number,
    productName: string
  ): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        sheetType: sheetType.toUpperCase(),
        lang: lang.toUpperCase(),
        productId: productId.toString(),
        type: "Product",
        productName: productName,
      },
    });
    window.open(routeData.href, "_blank");
    // productService
    //   .getFilePath({
    //     sheetType: sheetType.toUpperCase(),
    //     lang: lang.toUpperCase(),
    //     productId: productId,
    //   })
    //   .then((res) => {
    //     fileOpen.pdf(res.data.value, res.data.name);
    //     // fileLogService.insertLog({
    //     //   fileName: res.data.name,
    //     //   fileHash: res.data.fileHash,
    //     //   action: "R",
    //     //   userName: authModule.userName,
    //     // });
    //     overlayModule.hideOverlay();
    //   })
    //   .catch(() => {
    //     overlayModule.hideOverlay();
    //   });
  }

  private clickRow(row: ProductItemElabbed) {
    let lang = "";
    if (row.sheetYearIt > 0) {
      lang = "it";
    }
    if (row.sheetYearEn > 0) {
      lang = "en";
    }
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        sheetType: row.sheetType.toUpperCase(),
        lang: lang.toUpperCase(),
        productId: row.productId.toString(),
        type: "Product",
        productName: row.productName,
      },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>
<style scoped>
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}
.brack-world-custom > .v-data-table__wrapper > table > tbody > tr > td {
  word-break: break-word;
}
</style>

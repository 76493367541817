import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";

const CONTROLLER = "mansap";
class ManSapService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getFileNames() {
    return this.getAll<string[]>(`${CONTROLLER}/filenames`);
  }

  getPdf(fileName: string) {
    return this.getAll<FileResponse>(`${CONTROLLER}/getpdf`, {
      params: { fileName: fileName },
    });
  }
}

export const manSapService = new ManSapService();

import { DataTableHeader } from "vuetify";
import { translate } from "@/i18n/config";

export const handoverHeaders: DataTableHeader[] = [
  {
    text: translate("handover.CDL"),
    value: "location",
  },
  {
    text: translate("handover.Datetime"),
    value: "lastModifiedOn",
  },
  {
    text: translate("handover.Notes"),
    value: "notes",
  },
  {
    text: translate("handover.Author"),
    value: "lastModifiedBy",
  },
];

<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar :title="$tc('packsheet.Title')"></title-bar>
    </v-col>
    <v-col style="width: 450px">
      <input-center
        inPlaceHolder="INSERISCI CODICE SAP"
        v-model="sapCode"
        type="number"
        @submitSearch="submitSearch"
      ></input-center>
    </v-col>
    <v-col style="width: 270px">
      <v-btn
        class="mod--btn flex-grow-1"
        style="width: 100%"
        x-large
        @click="submitSearch"
      >
        {{ $tc("general.search") }}
      </v-btn>
    </v-col>
    <v-data-table
      v-if="fileNames.length"
      :headers="tableHeaders"
      :items="fileNames"
      class="data-table elevation-1 header-bold"
      disable-pagination
      hide-default-footer
      @click:row="openFile"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text v-if="item" class="mod--btn_text">
          <v-icon left color="primary" class="ml-1" :size="38">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { packSheetsService } from "@/services/api/packSheets.service";
import { DataTableHeader } from "vuetify";

const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component({})
export default class PackSheet extends Vue {
  sapCode: string = null;
  fileNames: { value: string }[] = [];
  tableHeaders: DataTableHeader[] = [
    {
      text: this.$tc("packsheet.Document"),
      align: "start",
      sortable: false,
      value: "value",
      width: "80vw",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  async submitSearch(): Promise<void> {
    if (this.sapCode) {
      overlayModule.showOverlay();

      const response = (await packSheetsService.getPdfNames(this.sapCode)).data;
      if (response.length) {
        this.fileNames = response.map((el) => ({
          value: el,
        }));
      }

      overlayModule.hideOverlay();
    } else {
      this.fileNames = [];
      snackbarModule.appearSnackbar({
        message: this.$tc("packsheet.SnackNoSap"),
        type: "warning",
      });
    }
  }

  openFile(row: { value: string }): void {
    const routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.value, type: "PACKSHEET" },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  margin-top: 12px;
}

.data-table > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 80vw;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

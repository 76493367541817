<template>
  <div class="page-container">
    <video ref="videoPlayer" style="height: 85%; width: 100%"></video>
    <v-row no-gutters style="flex: unset; gap: 16px">
      <v-btn @click="toggleVideo" x-large>
        {{ isPlaying ? $t("videoplayer.Stop") : $t("videoplayer.Resume") }}
      </v-btn>
      <v-btn @click="restartVideo" x-large>
        {{ $t("videoplayer.Restart") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { videoPlayerService } from "@/services/api/videoPlayer.service";

@Component({})
export default class VideoPlayer extends Vue {
  isPlaying = false;

  get fileName(): string {
    return this.$route.query.fileName.toString();
  }

  get folder(): string {
    return this.$route.query.folder.toString();
  }

  created(): void {
    this.loadVideo();
  }

  async loadVideo(): Promise<void> {
    await this.$nextTick();

    const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement;

    const response = await videoPlayerService.getVideoSource(
      this.folder,
      this.fileName
    );

    const arrayBuffer = response.data; // your ArrayBuffer goes here

    const videoBlob = new Blob([arrayBuffer], { type: "video/mp4" });
    const videoUrl = URL.createObjectURL(videoBlob);

    videoPlayer.src = videoUrl;
  }

  toggleVideo(): void {
    const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement;

    if (this.isPlaying) {
      videoPlayer.pause();
      this.isPlaying = false;
    } else {
      videoPlayer.play();
      this.isPlaying = true;
    }
  }

  restartVideo(): void {
    const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement;

    videoPlayer.currentTime = 0;
    if (this.isPlaying) {
      videoPlayer.pause();
      this.isPlaying = false;
    }
  }
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  height: calc(100svh - 105px);
  align-items: center;
  justify-content: space-around;
}
</style>

<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar :title="$tc('mp.title')"></title-bar>
    </v-col>
    <v-col style="width: 450px">
      <input-center
        :inTitle="$tc('mp.src')"
        :inPlaceHolder="$tc('mp.src')"
        v-model="MPCode"
        @submitSearch="submitSearch"
      ></input-center>
    </v-col>
    <v-col style="width: 270px">
      <v-btn class="mod--btn" x-large block @click="submitSearch">
        {{ $tc("general.search") }}
      </v-btn>
    </v-col>
    <v-data-table
      v-if="tableDrawItemsObject.length"
      :headers="tableDrawHeaders"
      :items="tableDrawItemsObject"
      disable-pagination
      class="data-table elevation-1 header-bold"
      hide-default-footer
      @click:row="clickRow"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text v-if="item.value" class="mod--btn_text">
          <v-icon left color="primary" class="ml-1" :size="38">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { mpService } from "@/services/api/mp.service";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { fileOpen } from "@/helpers/fileOpen";
import { MPFile } from "@/models/entities/fileFormats/fileFormats.interface";
import { fileLogService } from "@/services/api/fileLog.service";
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { DataTableHeader } from "vuetify";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class MPPage extends Vue {
  MPCode = "";

  tableDrawItems: string[] = [];

  tableDrawHeaders: DataTableHeader[] = [
    {
      text: this.$tc("mp.codeSAP"),
      align: "start",
      sortable: false,
      value: "value",
      width: "80vw",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  get tableDrawItemsObject(): Array<{ value: string }> {
    if (this.tableDrawItems && this.tableDrawItems.length > 0) {
      return this.tableDrawItems.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  submitSearch(): void {
    if (this.MPCode.length > 0) {
      overlayModule.showOverlay();
      mpService
        .getPdfNames(this.MPCode)
        .then((res) => {
          this.tableDrawItems = res.data;
          overlayModule.hideOverlay();
        })
        .catch(() => {
          this.tableDrawItems = [];
          overlayModule.hideOverlay();
        });
    } else {
      this.tableDrawItems = [];
      snackbarModule.appearSnackbar({
        message: this.$tc("mp.snackNoSAP"),
        type: "warning",
      });
    }
  }

  clickRow(row: any): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.value, type: "MP" },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>
<style scoped>
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  margin-top: 12px;
}

.data-table > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 80vw;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"orientationDiv",style:({
    background: _vm.isOrderButtonsOn ? '#cccccc' : '',
    padding: 0,
  })},[(_vm.isOrderButtonsOn)?_c('draggable',{class:_vm.isOrientationPortrait
        ? 'button-grid-vertical'
        : 'button-grid-horizontal',attrs:{"group":"buttons","sort":_vm.isOrderButtonsOn,"handle":".drag-handle"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.buttonListEnabled),callback:function ($$v) {_vm.buttonListEnabled=$$v},expression:"buttonListEnabled"}},_vm._l((_vm.buttonListEnabled),function(btn,i){return _c('div',{key:btn.id,staticClass:"grid-item"},[_c('img',{key:i,staticClass:"drag-handle",staticStyle:{"width":"100%"},attrs:{"alt":"icon","src":btn.id == 'MSA'
            ? require('@/assets/msa.svg')
            : btn.id == 'SAP'
            ? require('@/assets/sap.svg')
            : btn.id == 'USR'
            ? require('@/assets/usr.svg')
            : btn.id == 'SGQ'
            ? require('@/assets/SGQ.png')
            : btn.id == 'ST'
            ? require('@/assets/Schede tecniche.png')
            : btn.id == 'MNT'
            ? require('@/assets/MANUTENZIONE.png')
            : btn.id == 'UTILITY'
            ? require('@/assets/UTILITY.png')
            : btn.id == 'EQPMNG'
            ? require('@/assets/GESTIONE ATTREZZATURE.png')
            : btn.id == 'SOFTWARE'
            ? require('@/assets/SOFTWARE.png')
            : btn.id == 'HANDOVER'
            ? require('@/assets/HANDOVER.png')
            : ''}}),_c('span',{staticClass:"grid-item-text"},[_vm._v(" "+_vm._s(_vm.$tc(btn.t))+" ")])])}),0):_vm._e(),(!_vm.isOrderButtonsOn)?_c('div',{class:_vm.isOrientationPortrait
        ? 'button-grid-vertical'
        : 'button-grid-horizontal'},_vm._l((_vm.buttonListEnabled),function(btn,i){return _c('div',{key:btn.id,staticClass:"grid-item",on:{"click":function($event){return _vm.goToPage(btn.r, btn.id)}}},[_c('img',{key:i,staticStyle:{"width":"100%"},attrs:{"src":btn.id == 'MSA'
            ? require('@/assets/msa.svg')
            : btn.id == 'SAP'
            ? require('@/assets/sap.svg')
            : btn.id == 'USR'
            ? require('@/assets/usr.svg')
            : btn.id == 'SGQ'
            ? require('@/assets/SGQ.png')
            : btn.id == 'ST'
            ? require('@/assets/Schede tecniche.png')
            : btn.id == 'MNT'
            ? require('@/assets/MANUTENZIONE.png')
            : btn.id == 'UTILITY'
            ? require('@/assets/UTILITY.png')
            : btn.id == 'EQPMNG'
            ? require('@/assets/GESTIONE ATTREZZATURE.png')
            : btn.id == 'SOFTWARE'
            ? require('@/assets/SOFTWARE.png')
            : btn.id == 'HANDOVER'
            ? require('@/assets/HANDOVER.png')
            : '',"alt":"icon"}}),_c('span',{staticClass:"grid-item-text"},[_vm._v(_vm._s(_vm.$tc(btn.t)))])])}),0):_vm._e(),_c('v-footer',{staticClass:"flex-space-between",attrs:{"color":"col_base","app":"","height":100}},[_c('v-btn',{staticClass:"mod--btn mr-3",staticStyle:{"min-height":"50px","min-width":"50px","border-radius":"12px"},attrs:{"outlined":""},on:{"click":_vm.goChangePassword}},[_c('v-icon',{staticClass:"mod--icon",attrs:{"left":"","size":30}},[_vm._v("mdi-cog-outline")])],1),(_vm.footerUserName)?_c('p',{staticClass:"my-4"},[_c('span',[_vm._v(_vm._s(_vm.footerUserName))])]):_vm._e(),_c('v-spacer'),(!_vm.isOrderButtonsOn)?_c('v-btn',{staticClass:"mod--btn mod--btn_logout mr-2 btn-order",staticStyle:{"width":"220px","font-size":"22px"},attrs:{"outlined":""},on:{"click":_vm.restoreButtonSort}},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("home.ButtonRestoreSort"))+" ")])]):_vm._e(),_c('v-btn',{staticClass:"mod--btn mod--btn_logout mr-2 btn-order",staticStyle:{"width":"160px","font-size":"22px"},attrs:{"outlined":""},on:{"click":_vm.changeOrderButtonAction}},[_c('span',[_vm._v(" "+_vm._s(_vm.isOrderButtonsOn ? _vm.$tc("home.buttonSortOff") : _vm.$tc("home.buttonSortOn"))+" ")])]),_c('v-btn',{staticClass:"mod--btn",staticStyle:{"min-height":"80px"},attrs:{"outlined":""},on:{"click":_vm.submitLogout}},[_c('v-icon',{staticClass:"mod--icon",attrs:{"left":"","size":38}},[_vm._v(" mdi-logout")]),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$tc("general.logout"))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('tutorial.Title')"
        :is-parent-element="false"
        forced-previous-component="Training"
      ></title-bar>
    </v-col>
    <v-col style="width: 450px">
      <select-row
        v-model="selectedFolder"
        :inItems="folderNames"
        hideTitle
        :in-place-holder="$t('tutorial.ChooseFolder')"
        in-title=""
        @inChanged="onFolderSelection"
      >
      </select-row>
    </v-col>

    <v-data-table
      v-if="dataTableItems.length"
      :headers="headers"
      :items="dataTableItems"
      disable-pagination
      fixed-header
      hide-default-footer
      disable-sort
      disable-filtering
      class="data-table elevation-1 header-bold"
      @click:row="openFile"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text v-if="item" class="mod--btn_text">
          <v-icon left color="primary" class="ml-1" :size="38">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { tutorialService } from "@/services/api/tutorial.service";
import { DataTableHeader } from "vuetify";

@Component({})
export default class Tutorial extends Vue {
  folderNames: string[] = [];
  fileNames: string[] = [];
  selectedFolder: string = null;
  headers: DataTableHeader[] = [
    {
      text: this.$tc("tutorial.FileName"),
      value: "value",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  get dataTableItems(): { text: string; value: string }[] {
    return this.fileNames.map((el) => ({
      text: el,
      value: el,
    }));
  }

  created(): void {
    this.loadFolders();
  }

  async loadFolders(): Promise<void> {
    const response = await tutorialService.getFolderNames();

    this.folderNames = response.data;
  }

  async onFolderSelection(): Promise<void> {
    try {
      const response = await tutorialService.getFileNames(this.selectedFolder);

      this.fileNames = response.data;
    } catch (error) {
      this.fileNames = [];
    }
  }

  openFile(row: { value: string }): void {
    const routeData = this.$router.resolve({
      name: "VideoPlayer",
      query: { folder: this.selectedFolder, fileName: row.value },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  margin-top: 12px;
}

.data-table > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 80vw;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

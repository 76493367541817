<template>
  <v-row
    :style="hideTitle && `'justify-content: ${justifyContent ?? 'center'}'`"
  >
    <v-col v-if="!hideTitle" cols="4">
      <strong
        :style="
          $vuetify.breakpoint.mdAndDown ? 'font-size: 26px' : 'font-size: 18px'
        "
      >
        {{ inTitle }}
      </strong>
    </v-col>
    <v-col
      :cols="hideTitle ? 'auto' : 8"
      :style="{
        width: hideTitle ? width ?? '450px' : 'unset',
        padding: paddingZero ? '0px' : undefined,
      }"
    >
      <v-select
        class="white mod--select"
        :height="$vuetify.breakpoint.mdAndDown ? 96 : 40"
        :clearable="clearable"
        v-model="valueModel"
        :items="inItems"
        :placeholder="inPlaceHolder"
        :label="label"
        outlined
        :return-object="returnObject"
        :item-text="itemText"
        :item-value="itemValue"
        hide-details
        @input="inChanged"
        @click:clear="$emit('click:clear')"
        :readonly="inIsReadonly"
        :rules="rules"
        :class="
          $vuetify.breakpoint.mdAndDown
            ? label
              ? 'mod--select v-text-field--filled-big-with-label'
              : 'mod--select v-text-field--filled-big'
            : 'mod--select v-text-field--filled-small'
        "
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator";

@Component
export default class SelectRow extends Vue {
  @VModel({ default: false })
  valueModel: undefined;
  @Prop({ required: true, type: String, default: "" })
  private inTitle: string | undefined;
  @Prop({ required: true, type: Array, default: "" })
  private inItems: string[] | undefined;
  @Prop({ required: false, type: String, default: "" })
  private inPlaceHolder: string | undefined;
  @Prop({ required: false, type: Boolean, default: false })
  private inIsReadonly: boolean;
  @Prop({ required: false, default: () => [], type: Array })
  private rules: Array<(v: string) => string | boolean>;
  @Prop({ required: false, type: Boolean, default: false })
  paddingZero: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  hideTitle: boolean;
  @Prop({ required: false, type: String })
  itemText: string;
  @Prop({ required: false, type: String })
  itemValue: string;
  @Prop({ required: false, default: false, type: Boolean })
  clearable: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  returnObject: boolean;
  @Prop({ required: false, type: String, default: null })
  label: string;
  @Prop({ required: false, type: String, default: null })
  width: string;
  @Prop({ required: false, type: String, default: null })
  justifyContent: string;

  private inChanged(value: number): void {
    if (value != null) {
      this.$emit("inChanged", value);
    }
  }
}
</script>
<style lang="scss">
.v-text-field--filled-small > .v-input__control > .v-input__slot {
  min-height: 18px;
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
  align-items: center;
  padding: 0 12px !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.v-text-field--filled-big > .v-input__control > .v-input__slot {
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
  padding: 0 12px !important;
  font-size: 16px !important;
  align-items: center;
}

.mod--select :deep(.v-input__control) {
  color: red;
}

.v-text-field--filled-big-with-label
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  height: 100%;
}

.v-list-item-small {
  min-height: 12px;

  .v-list-item__title-small {
    font-size: 12px;
  }
}

/*
.mod--select-with-label .v-select__slot {
  height: 100%;
}
*/
</style>

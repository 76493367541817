var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-col',{staticClass:"pb-12",attrs:{"cols":"12"}},[_c('title-bar',{attrs:{"title":_vm.$tc('utility.title'),"is-parent-element":true}})],1),_c('div',{class:_vm.isOrientationPortrait
        ? 'button-grid-vertical'
        : 'button-grid-horizontal'},_vm._l((_vm.buttonListEnabled),function(btn){return _c('div',{key:btn.id,staticClass:"grid-item",on:{"click":function($event){return _vm.goToPage(btn.r, btn.id)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"icon","src":btn.id == 'RUBRIC'
            ? require('@/assets/RUBRICA.png')
            : btn.id == 'TRAINING'
            ? require('@/assets/RUBRICA.png')
            : ''}}),_c('span',{staticClass:"grid-item-text"},[_vm._v(_vm._s(_vm.$tc(btn.t)))])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-col',{staticClass:"pb-12",attrs:{"cols":"12"}},[_c('title-bar',{attrs:{"title":_vm.$tc('datasheetList.title'),"is-parent-element":true}})],1),_c('div',{class:_vm.isOrientationPortrait
        ? 'button-grid-vertical'
        : 'button-grid-horizontal'},_vm._l((_vm.buttonListEnabled),function(btn,i){return _c('div',{key:btn.id,staticClass:"grid-item",on:{"click":function($event){return _vm.goToPage(btn.r, btn.id)}}},[_c('img',{key:i,staticStyle:{"width":"100%"},attrs:{"src":btn.id == 'SMP'
            ? require('@/assets/smp.png')
            : btn.id == 'SAT'
            ? require('@/assets/Scheda Tecnica Attrezzatura.png')
            : btn.id == 'SDS'
            ? require('@/assets/SDS.png')
            : btn.id == 'STT'
            ? require('@/assets/Scheda Tecnica TT&FIN.png')
            : btn.id == 'STS'
            ? require('@/assets/Scheda Tecnica Saldatura.png')
            : btn.id == 'PACKSHEET'
            ? require('@/assets/Schede imballo.png')
            : '',"alt":"icon"}}),_c('span',{staticClass:"grid-item-text"},[_vm._v(_vm._s(_vm.$tc(btn.t)))])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "rapidLink";
class RapidLinkService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }
  getLinkByAuth(authId: string) {
    return this.getAll<string>(`${CONTROLLER}/linktoopen?authId=${authId}`);
  }

  getFileToOpen(authId: string) {
    return this.getAll<FileResponse>(
      `${CONTROLLER}/filetoopen?authId=${authId}`
    );
  }

  getRubricFile() {
    return this.getAll<FileResponse>(`${CONTROLLER}/rubricfile`);
  }
}

export const rapidLinkService = new RapidLinkService();

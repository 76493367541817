<template>
  <div>
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('utility.title')"
        :is-parent-element="true"
      ></title-bar>
    </v-col>
    <div
      :class="
        isOrientationPortrait
          ? 'button-grid-vertical'
          : 'button-grid-horizontal'
      "
    >
      <div
        v-for="btn in buttonListEnabled"
        :key="btn.id"
        class="grid-item"
        @click="goToPage(btn.r, btn.id)"
      >
        <img
          alt="icon"
          style="width: 100%"
          :src="
            btn.id == 'RUBRIC'
              ? require('@/assets/RUBRICA.png')
              : btn.id == 'TRAINING'
              ? require('@/assets/RUBRICA.png')
              : ''
          "
        />
        <span class="grid-item-text">{{ $tc(btn.t) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { HomeButton } from "@/models/entities/components/components.interface";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { buttonSortService } from "@/services/api/buttonSort.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { rapidLinkService } from "@/services/api/rapidLink.service";
import { RapidLinkEnum } from "@/models/enumerations/rapidLinkEnum";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component({})
export default class Maintenance extends Vue {
  buttonListEnabled: HomeButton[] = [];
  buttonTraining = {
    id: "TRAINING",
    r: "utility/training",
    t: "home.buttonTRAINING",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };

  buttonList = [
    {
      id: "RUBRIC",
      r: "utility/rubric",
      t: "home.buttonRUBRIC",
      c: "mod--color_btn_equipment",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "RUBRIC";
        }).length > 0,
      sort: 0,
    },
  ];

  get isOrientationPortrait() {
    return authModule.isOrientationPortrait;
  }

  created(): void {
    this.setButtonListEnabled();
  }

  async setButtonListEnabled(): Promise<void> {
    overlayModule.showOverlay();

    const response = await buttonSortService.getAuthsByParent("UTILITY");

    this.buttonListEnabled = this.buttonList.filter((value) =>
      response.data.includes(value.id)
    );

    const trainingAuths = await buttonSortService.getAuthsByParent("TRAINING");
    if (trainingAuths.data.length > 0) {
      this.buttonListEnabled.push(this.buttonTraining);
    }

    overlayModule.hideOverlay();
  }

  async goToPage(path: string, id: string) {
    if (this.isRapidLink(id)) {
      const response = await rapidLinkService.getLinkByAuth(RapidLinkEnum[id]);
      window.open(response.data, "_blank");
    } else {
      await this.$router.replace({ path: path });
    }
  }

  isRapidLink(authId: string): boolean {
    return ["RUBRIC"].includes(authId);
  }
}
</script>

<style scoped>
.button-grid-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.button-grid-vertical {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-item {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.grid-item-text {
  font-weight: bold;
  text-align: center;
}
</style>

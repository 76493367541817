<template>
  <v-dialog v-model="show" :width="isDesktop ? '35vw' : '75vw'" persistent>
    <v-card>
      <v-card-title>
        {{ $t("inserthandoverdialog.Title") }}
      </v-card-title>

      <v-card-text>
        <div class="form-insert">
          <select-row
            v-model="selectedDepartmentLocal"
            inTitle=""
            :inItems="departments"
            hideTitle
            :in-place-holder="$tc('inserthandoverdialog.SelectDepartment')"
            return-object
            item-text="denominazione"
            item-value="codreparto"
            :width="!isDesktop ? '350px' : null"
            justify-content="flex-start"
          ></select-row>
          <input-center
            :width="isDesktop ? '426px' : '326px'"
            v-model="locationLocal"
            :inPlaceHolder="$tc('inserthandoverdialog.InsertLocation')"
          ></input-center>
          <div style="display: flex; gap: 8px; width: 100%">
            <v-textarea
              v-model="notes"
              style="width: 100%"
              label="Note"
              outlined
              hide-details
              background-color="white"
            ></v-textarea>
            <speech-to-text :notes.sync="notes"></speech-to-text>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="actions-buttons">
          <v-btn @click="$emit('click:close')" width="45%">
            {{ $t("general.close") }}
          </v-btn>
          <v-btn color="primary" @click="handleInsertNotes" width="45%">
            {{ $t("general.confirm") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Model, Prop, PropSync } from "vue-property-decorator";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import SpeechToText from "@/components/Handover/SpeechToText.vue";

const snackbarModule = getModule(SnackbarModule, store);

@Component({
  components: { SpeechToText },
})
export default class InsertHandoverDialog extends Vue {
  @Model()
  show: boolean;

  @Prop({ required: false, type: Array, default: () => [] })
  departments: DepartmentItemInterface[];

  @PropSync("selectedDepartment", { type: Object, required: false })
  selectedDepartmentLocal: DepartmentItemInterface;

  @PropSync("location", { type: String, required: false })
  locationLocal: string;

  notes: string = null;

  get isDesktop(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  handleInsertNotes(): void {
    if (!this.selectedDepartmentLocal || !this.locationLocal || !this.notes) {
      snackbarModule.appearSnackbar({
        message: this.$t("inserthandoverdialog.MissingFields"),
        type: "error",
      });
      return;
    }

    this.$emit("saveNotes", this.notes);
  }
}
</script>

<style scoped>
.form-insert {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actions-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>

<template>
  <div>
    <v-text-field
      class="input-center"
      v-model="valueModel"
      :disabled="disabled"
      :clearable="clearable"
      :label="inPlaceHolder"
      :solo="!needLabel"
      outlined
      hide-details
      :readonly="inIsReadonly"
      @input="inChanged"
      @click="$emit('click:textField')"
      :rules="rules"
      hint=""
      :class="
        $vuetify.breakpoint.mdAndDown
          ? needLabel
            ? 'v-text-field--filled-big-with-label'
            : 'v-text-field--filled-big'
          : 'v-text-field--filled-small'
      "
      style="max-width: 500px"
      :style="{
        width: width ?? 'unset',
      }"
      :height="$vuetify.breakpoint.mdAndDown ? 96 : 40"
      :type="type"
      hide-spin-buttons
      @keypress.enter="submitSearch"
      @blur="$emit('onBlur')"
    ></v-text-field>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
@Component
export default class InputCenter extends Vue {
  @VModel({ default: null })
  valueModel: undefined;
  // Defines input title.
  @Prop({ required: false, type: String, default: "Inserisci un valore" })
  private inPlaceHolder: string;
  // Defines input type.
  @Prop({ required: false, type: Boolean, default: false })
  private inIsReadonly: boolean;
  @Prop({ required: false, type: Array, default: () => [] })
  private rules!: Array<(v: string) => string | boolean>;
  @Prop({ required: false, type: String, default: "text" })
  private type: string;
  @Prop({ required: false, type: Boolean, default: false })
  private disabled: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  private clearable: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  needLabel: boolean;
  @Prop({ required: false, type: String, default: null })
  width: boolean;

  private togglePassword = false;

  private inChanged(): void {
    this.$emit("inChanged");
  }

  private submitSearch() {
    this.$emit("submitSearch");
  }
}
</script>
<style lang="scss">
.v-text-field--filled-small > .v-input__control > .v-input__slot {
  min-height: 18px;
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
}
.v-text-field--filled-big > .v-input__control > .v-input__slot {
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
  font-size: 24px;
}

.v-text-field--filled-big-with-label > .v-input__control > .v-input__slot {
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
  font-size: 24px;
}

.v-text-field--filled-big-with-label
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  height: 100%;
}

.input-center :deep(.v-input__control) {
  min-height: 0 !important;
}
</style>

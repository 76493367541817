<template>
  <v-dialog
    v-model="show"
    width="350px"
    @click:outside="$emit('click:outside')"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("calculator.Title") }}</span>
      </v-card-title>

      <div class="calculator-container">
        <input type="text" class="result" :value="result" readonly />
        <div class="calculator-buttons-container">
          <!-- Calculator buttons -->
          <button
            v-for="button in buttons"
            :key="button.buttonText"
            class="button"
            @click="handleClick(button)"
          >
            {{ button.buttonText }}
          </button>
        </div>
        <v-row no-gutters style="gap: 12px" justify="end">
          <button class="clear-button" @click="handleClear">C</button>
          <button class="equal-button" @click="calculate">=</button>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Model } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";

interface CalculatorButton {
  buttonText: string;
  isOperand: boolean;
}

const authModule = getModule(AuthModule, store);

@Component({})
export default class Calculator extends Vue {
  @Model()
  show: boolean;

  buttons: CalculatorButton[] = [
    {
      buttonText: "1",
      isOperand: false,
    },
    {
      buttonText: "2",
      isOperand: false,
    },
    {
      buttonText: "3",
      isOperand: false,
    },
    {
      buttonText: "+",
      isOperand: true,
    },
    {
      buttonText: "4",
      isOperand: false,
    },
    {
      buttonText: "5",
      isOperand: false,
    },
    {
      buttonText: "6",
      isOperand: false,
    },
    {
      buttonText: "-",
      isOperand: true,
    },
    {
      buttonText: "7",
      isOperand: false,
    },
    {
      buttonText: "8",
      isOperand: false,
    },
    {
      buttonText: "9",
      isOperand: false,
    },
    {
      buttonText: "*",
      isOperand: true,
    },
    {
      buttonText: "0",
      isOperand: false,
    },
    {
      buttonText: ".",
      isOperand: false,
    },
    {
      buttonText: "00",
      isOperand: false,
    },
    {
      buttonText: "/",
      isOperand: true,
    },
  ];
  result = "";
  calculated = false;

  get isVerticalOriented(): boolean {
    return authModule.isOrientationPortrait;
  }

  handleClick(button: CalculatorButton): void {
    if (button.isOperand) {
      this.handleOperandClick(button);
    } else {
      this.handleNumberClick(button);
    }
  }

  handleNumberClick(button: CalculatorButton): void {
    if (this.calculated) {
      this.result = button.buttonText;
      this.calculated = false;
    } else {
      this.result += button.buttonText;
    }
  }

  handleOperandClick(button: CalculatorButton): void {
    //se termina con un operatore lo sostituisce con quello premuto
    if (/[+*/-]$/.test(this.result)) {
      this.result = this.result.slice(0, -1) + button.buttonText;
    } else {
      this.result += button.buttonText;
    }
    this.calculated = false;
  }

  handleClear(): void {
    this.calculated = false;
    this.result = "";
  }

  calculate(): void {
    try {
      const fixedString = this.result.replace(/(^|[^0-9])0+(\d+)/g, "$1$2");

      const evaluatedResult = eval(fixedString);
      if (evaluatedResult === Infinity || evaluatedResult === -Infinity) {
        throw new Error("calculator.ImpossibleOperation");
      }

      this.result = Number.isFinite(evaluatedResult)
        ? evaluatedResult
        : "Error";
      this.calculated = true;
    } catch (error) {
      if (error.message === "calculator.ImpossibleOperation")
        this.result = this.$tc("calculator.ImpossibleOperation");
      else this.result = "Error";
    }
  }
}
</script>

<style scoped>
.calculator-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calculator-buttons-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.button {
  background-color: #f0f0f0;
  aspect-ratio: 1 / 1;
}

.result {
  text-align: right;
  margin-bottom: 10px;
  background: #dad1cf;
}

.clear-button {
  background: #ff6666;
  color: white;
  width: 25%;
  height: 50px;
}

.equal-button {
  background: #ff6666;
  color: white;
  width: 45%;
  height: 50px;
}
</style>

import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "tutorial";
class TutorialService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getFolderNames() {
    return this.getAll<string[]>(`${CONTROLLER}/foldernames`);
  }

  getFileNames(folderName: string) {
    return this.getAll<string[]>(`${CONTROLLER}/filenames`, {
      params: { folderName: folderName },
    });
  }
}

export const tutorialService = new TutorialService();

<template>
  <div>
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('training.Title')"
        :is-parent-element="false"
        forced-previous-component="utility"
      ></title-bar>
    </v-col>
    <div
      :class="
        isOrientationPortrait
          ? 'button-grid-vertical'
          : 'button-grid-horizontal'
      "
    >
      <div
        v-for="btn in buttonListEnabled"
        :key="btn.id"
        class="grid-item"
        @click="goToPage(btn.r)"
      >
        <img
          alt="icon"
          style="width: 100%"
          :src="
            btn.id == 'TUTORIAL'
              ? require('@/assets/BUSINESS OBJECT.png')
              : btn.id == 'MANSAP'
              ? require('@/assets/DASHBOARD STAIN.png')
              : ''
          "
        />
        <span class="grid-item-text">{{ $tc(btn.t) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { buttonSortService } from "@/services/api/buttonSort.service";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import store from "@/store";
import { HomeButton } from "@/models/entities/components/components.interface";
import AuthModule from "@/store/modules/auth.module";

const overlayModule = getModule(OverlayModule, store);
const authModule = getModule(AuthModule, store);

@Component({})
export default class Training extends Vue {
  buttonListEnabled: HomeButton[] = [];
  buttonList = [
    {
      id: "TUTORIAL",
      r: "/utility/training/tutorial",
      t: "home.buttonTUTORIAL",
      c: "mod--color_btn_equipment",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "TUTORIAL";
        }).length > 0,
      sort: 0,
    },
    {
      id: "MANSAP",
      r: "/utility/training/mansap",
      t: "home.buttonMANSAP",
      c: "mod--color_btn_equipment",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "MANSAP";
        }).length > 0,
      sort: 0,
    },
  ];

  get isOrientationPortrait() {
    return authModule.isOrientationPortrait;
  }

  created(): void {
    this.setButtonListEnabled();
  }

  async setButtonListEnabled(): Promise<void> {
    overlayModule.showOverlay();

    const response = await buttonSortService.getAuthsByParent("TRAINING");

    this.buttonListEnabled = this.buttonList.filter((value) =>
      response.data.includes(value.id)
    );

    overlayModule.hideOverlay();
  }

  async goToPage(path: string): Promise<void> {
    await this.$router.replace({ path: path });
  }
}
</script>

<style scoped>
.button-grid-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.button-grid-vertical {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-item {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.grid-item-text {
  font-weight: bold;
  text-align: center;
}
</style>

<template>
  <div style="padding: 12px">
    <div class="header-container">
      <v-row no-gutters align="center" style="gap: 24px">
        <v-col cols="3">
          <span>{{ $t("smallmaintenanceactivities.AssetId") }}</span>
        </v-col>
        <v-col cols="5">
          <div class="header-field-slot">
            {{ assetId }}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" style="gap: 24px">
        <v-col cols="3">
          <span>
            {{ $t("smallmaintenanceactivities.SmallMaintenancePlan") }}
          </span>
        </v-col>
        <v-col cols="5">
          <div class="header-field-slot">
            {{ smallMaintenancePlan }}
          </div>
        </v-col>
        <v-col cols="2">
          <v-btn
            plain
            style="text-decoration: underline"
            @click="showLegendDialog = true"
          >
            Mostra legenda
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" style="gap: 24px">
        <v-col cols="3">
          <span>
            {{ $t("smallmaintenanceactivities.Date") }}
          </span>
        </v-col>
        <v-col cols="5">
          <div class="header-field-slot">
            {{ formattedDateHeader }}
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="table-container">
      <table class="table-days">
        <thead>
          <tr>
            <th>{{ $t("smallmaintenanceactivities.ActivityDescription") }}</th>
            <th>{{ $t("smallmaintenanceactivities.Frequency") }}</th>
            <th
              v-for="day in days"
              :key="day.day"
              :class="{
                'day-is-completed': day.isCompleted,
              }"
            >
              {{ day.day }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in questions" :key="question.questionId">
            <td class="question-text-descr">{{ question.questionDescr }}</td>
            <td class="question-text-freq">{{ question.questionFreq }}</td>
            <td
              v-for="date in question.currentMonthDates"
              class="day-cell"
              :key="date.day"
              @click="openNotes(date)"
            >
              <div
                v-if="date.answer"
                class="base-answer"
                :class="getClassByType(date.answer)"
              >
                <span
                  v-if="date.cellText"
                  style="font-size: 16px; font-weight: bold"
                  :style="{
                    color: date.cellText === 'KO' ? '#da0909' : 'black',
                  }"
                >
                  {{ date.cellText }}
                </span>
                <span
                  v-if="date.cellNotes"
                  style="font-size: 16px; font-weight: bold"
                >
                  {{ date.cellText ? "-" : null }}{{ "N" }}
                </span>
              </div>
              <span v-else-if="date.isPlanned"></span>
              <span v-else>X</span>
            </td>
          </tr>
        </tbody>
      </table>

      <v-dialog v-if="showNotesDialog" v-model="showNotesDialog" width="45vw">
        <v-card>
          <v-card-title>
            {{ $t("smallmaintenanceactivities.NotesDialogTitle") }}
          </v-card-title>
          <v-card-text>
            <span style="font-size: 20px">{{ selectedNotes }}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showNotesDialog = false">
              {{ $t("general.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <legend-dialog
      v-model="showLegendDialog"
      :answer-types="answerTypes"
    ></legend-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import {
  DayAnswer,
  SmallMaintenanceQuestion,
} from "@/models/entities/maintenance/smallMaintenanceQuestion.interface";
import { maintenanceService } from "@/services/api/maintenance.service";
import { DayCompilation } from "@/models/entities/maintenance/smallMaintenanceDayCompilation.interface";
import LegendDialog from "@/components/smallMaintenance/LegendDialog.vue";
import { SmallMaintenanceAnswerTypes } from "@/models/entities/maintenance/smallMaintenancePlan.interface";

@Component({
  name: "SmallMaintenanceActivities",
  components: { LegendDialog },
})
export default class SmallMaintenanceActivities extends Vue {
  questions: SmallMaintenanceQuestion[] = [];
  days: DayCompilation[] = [];
  selectedNotes: string = null;
  showNotesDialog = false;
  showLegendDialog = false;
  answerTypes: SmallMaintenanceAnswerTypes[] = [];

  get assetId(): string {
    return this.$route.query.assetId.toString();
  }

  get smallMaintenancePlanId(): number {
    return Number(this.$route.query.smallMaintenancePlanId.toString());
  }

  get smallMaintenancePlan(): string {
    return this.$route.query.smallMaintenancePlan.toString();
  }

  get date(): string {
    return this.$route.query.date.toString();
  }

  get formattedDateHeader(): string {
    const splittedDate = this.date.split("-");

    return `${splittedDate[1]}/${splittedDate[0]}`;
  }

  created(): void {
    this.getAllDays();
    this.getAllQuestions();
    this.loadAnswerTypes();
  }

  async loadAnswerTypes(): Promise<void> {
    const response = await maintenanceService.getSmallMaintenanceAnswerTypes();
    this.answerTypes = response.data;
  }

  async getAllDays(): Promise<void> {
    const response = await maintenanceService.getAllDaysCompilation(
      this.assetId,
      this.smallMaintenancePlanId,
      this.date
    );

    this.days = response.data;
  }

  async getAllQuestions(): Promise<void> {
    const response = await maintenanceService.getAllQuestions(
      this.assetId,
      this.smallMaintenancePlanId
    );

    this.questions = response.data;
  }

  getClassByType(answerType: number): string {
    switch (answerType) {
      case 1: //Effettuato
        return "answer-made";
      case 2: //Non effettuabile
        return "answer-not-feasible";
      case 3: //Non effettuato
        return "answer-not-made";
      case 4: //Macchina ferma
        return "answer-stopped";
    }
  }

  openNotes(date: DayAnswer): void {
    if (date.cellNotes) {
      this.selectedNotes = date.cellNotes;

      this.showNotesDialog = true;
    }
  }
}
</script>

<style scoped>
.header-container {
  width: 65vw;
  margin-bottom: 24px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-field-slot {
  border-radius: 4px;
  padding: 4px 16px;
  width: fit-content;
  border: 1px solid #d5d9df;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Abilita lo scroll orizzontale */
  -webkit-overflow-scrolling: touch; /* Aggiunto per migliorare l'esperienza su dispositivi mobili */
}

.table-days {
  width: 100%;
  font-size: 20px;
}

table,
th,
td {
  border: 1px solid #9c9796;
  border-collapse: separate;
  border-spacing: 0;
}

table {
  height: 1px;
}
tr {
  height: 100%;
}
td {
  height: 100%;
  min-width: 45px;
  min-height: 45px;
}
td > div {
  height: 100%;
}

table thead th:first-child,
table tbody td:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
}

table thead th:nth-child(2),
table tbody td:nth-child(2) {
  position: sticky;
  left: 300px; /* La larghezza della prima colonna + eventuali spazi tra le colonne */
  background: white;
  z-index: 3;
}

.day-cell {
  width: 45px;
  height: 45px;
  text-align: center;
}

.question-text-descr {
  padding: 2px;
  min-width: 300px;
}

.question-text-freq {
  padding: 2px;
  min-width: 120px;
}

.base-answer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-made,
.outcome-ok {
  background: green;
}

.answer-not-feasible,
.outcome-not-made {
  background: yellow;
}

.answer-not-made,
.outcome-stopped {
  background: orange;
}

.answer-stopped,
.outcome-ko {
  background: red;
}

.day-is-completed {
  background: #0171b6;
}
</style>

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { loadLanguageAsync } from "@/i18n/config";
import store from "@/store";
import HomePage from "../views/Home.vue";
import MSAPage from "../views/MSA.vue";
import EquipmentPage from "../views/Datasheets/Equipment.vue";
import ChangePassword from "../views/ChangePassword.vue";
import EquipmentDetailsPage from "../views/Datasheets/EquipmentDetails.vue";
import MPPage from "../views/Datasheets/MP.vue";
import ProductsPage from "../views/Datasheets/Products.vue";
import LoginPage from "../views/Login.vue";
import UserManagementPage from "../views/UserManagement.vue";
import PdfOpener from "../views/PdfOpener.vue";
import { authService } from "@/services/api/authenticate.service";
import AuthModule from "@/store/modules/auth.module";
import { getModule } from "vuex-module-decorators";
import DatasheetList from "@/views/Datasheets/DatasheetList.vue";
import Stt from "@/views/Datasheets/Stt.vue";
import Sts from "@/views/Datasheets/Sts.vue";
import Utility from "@/views/Utility/Utility.vue";
import Maintenance from "@/views/Maintenance/Maintenance.vue";
import SmallMaintenance from "@/views/Maintenance/SmallMaintenance.vue";
import Manuals from "@/views/Maintenance/Manuals.vue";
import { adminService } from "@/services/api/admin.service";
import { usersService } from "@/services/api/users.service";
import SnackbarModule from "@/store/modules/snackbar.module";
import Rubric from "@/views/Utility/Rubric.vue";
import EquipmentManagement from "@/views/EquipmentManagement/EquipmentManagement.vue";
import ActivePartsAndNormalized from "@/views/EquipmentManagement/ActivePartsAndNormalized.vue";
import ActiveParts from "@/views/EquipmentManagement/ActiveParts.vue";
import Normalized from "@/views/EquipmentManagement/Normalized.vue";
import SecurityQuestion from "@/views/SecurityQuestion.vue";
import RecoveryPassword from "@/views/RecoveryPassword.vue";
import SmallMaintenancePlan from "@/views/Maintenance/SmallMaintenancePlan.vue";
import PackSheet from "@/views/Datasheets/PackSheet.vue";
import Software from "@/views/Software.vue";
import Handover from "@/views/Handover.vue";
import Training from "@/views/Training/Training.vue";
import Tutorial from "@/views/Training/Tutorial.vue";
import Mansap from "@/views/Training/Mansap.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import SmallMaintenanceActivities from "@/views/Maintenance/SmallMaintenanceActivities.vue";

const authModule = getModule(AuthModule, store);
const snackbarModule = getModule(SnackbarModule, store);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "home",
    component: HomePage,
  },
  {
    path: "/msa",
    name: "msa",
    component: MSAPage,
  },
  {
    path: "/datasheetList/equipment",
    name: "equipment",
    component: EquipmentPage,
  },
  {
    path: "/datasheetList/equipmentDetails",
    name: "equipmentDetails",
    component: EquipmentDetailsPage,
  },
  {
    path: "/datasheetList/mp",
    name: "mp",
    component: MPPage,
  },
  {
    path: "/datasheetList/products",
    name: "products",
    component: ProductsPage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/userManagement",
    name: "userManagement",
    component: UserManagementPage,
  },
  {
    path: "/datasheetList",
    name: "datasheetList",
    component: DatasheetList,
  },
  {
    path: "/software",
    name: "Software",
    component: Software,
  },
  {
    path: "/handover",
    name: "Handover",
    component: Handover,
  },
  {
    path: "/utility/training",
    name: "Training",
    component: Training,
  },
  {
    path: "/utility/training/tutorial",
    name: "Tutorial",
    component: Tutorial,
  },
  {
    path: "/utility/training/mansap",
    name: "Mansap",
    component: Mansap,
  },
  {
    path: "/datasheetList/stt",
    name: "stt",
    component: Stt,
  },
  {
    path: "/datasheetList/sts",
    name: "sts",
    component: Sts,
  },
  {
    path: "/datasheetList/packsheet",
    name: "PackSheet",
    component: PackSheet,
  },
  {
    path: "/utility",
    name: "utility",
    component: Utility,
  },
  {
    path: "/utility/rubric",
    name: "rubric",
    component: Rubric,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
  },
  {
    path: "/maintenance/smallMaintenance",
    name: "smallMaintenance",
    component: SmallMaintenance,
  },
  {
    path: "/maintenance/smallMaintenance/smallMaintenacePlan",
    name: "smallMaintenancePlan",
    component: SmallMaintenancePlan,
  },
  {
    path: "/maintenance/smallMaintenance/activities",
    name: "SmallMaintenanceActivities",
    component: SmallMaintenanceActivities,
  },
  {
    path: "/maintenance/manuals",
    name: "manuals",
    component: Manuals,
  },
  {
    path: "/equipmentmanagement",
    name: "equipmentManagement",
    component: EquipmentManagement,
  },
  {
    path: "/equipmentmanagement/activepartsandnormalized",
    name: "activePartsAndNormalized",
    component: ActivePartsAndNormalized,
  },
  {
    path: "/equipmentmanagement/activeparts",
    name: "activeParts",
    component: ActiveParts,
  },
  {
    path: "/equipmentmanagement/normalized",
    name: "normalized",
    component: Normalized,
  },
  {
    path: "/PdfOpener",
    name: "PdfOpener",
    component: PdfOpener,
    props: true,
  },
  {
    path: "/videoplayer",
    name: "VideoPlayer",
    component: VideoPlayer,
    props: true,
  },
  {
    path: "/ChangePassword",
    name: "changePassword",
    props: true,
    component: ChangePassword,
  },
  {
    path: "/securityquestion",
    name: "setSecurityQuestion",
    props: true,
    component: SecurityQuestion,
  },
  {
    path: "/recoverypassword",
    name: "recoveryPassword",
    component: RecoveryPassword,
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async function (to, from, next) {
  if (to.name !== "login") snackbarModule.hideSnackbar();
  await loadLanguageAsync("it");
  /* eslint-disable */

  window.document.title = "DIGITA";

  const token = authModule.token;
  const redirectIfNot = async (routeTo: string) => {
    const allAuths = (await adminService.getAllAuths()).data;
    if (
      allAuths.filter((x) => {
        return x.authId === routeTo;
      }).length > 0
    ) {
      authModule.setCurrentAuthId(routeTo);
      next();
    } else {
      next({ path: "/home" });
    }
  };

  if (to.name === "recoveryPassword") {
    next();
    return;
  }

  if (token) {
    try {
      await authService.checkLogin();
    } catch (err) {
      authModule.logout();
      return;
    }
    if (to.path === "/login") {
      next({ path: "/home" });
    } else {
      switch (to.name) {
        case "msa":
          await redirectIfNot("MSA");
          break;
        case "theseus":
          await redirectIfNot("SGQ");
          break;
        case "userManagement":
          await redirectIfNot("USR");
          break;
        case "equipment":
        case "equipmentDetails":
          await redirectIfNot("SAT");
          break;
        case "mp":
          await redirectIfNot("SMP");
          break;
        case "products":
          await redirectIfNot("SDS");
          break;
        case "stt":
          await redirectIfNot("STT");
          break;
        case "sts":
          await redirectIfNot("STS");
          break;
        case "smallMaintenance":
          await redirectIfNot("SMALLMNT");
          break;
        case "rubric":
          await redirectIfNot("RUBRIC");
          break;
        case "manuals":
          await redirectIfNot("MANMNT");
          break;
        case "activePartsAndNormalized":
          await redirectIfNot("ACTIVEPARTS");
          break;
        case "activeParts":
          await redirectIfNot("ACTIVEPARTS");
          break;
        case "normalized":
          await redirectIfNot("ACTIVEPARTS");
          break;
        default:
          next();
          break;
      }
    }
  } else {
    await usersService.logout();
    if (to.name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
});
export default router;

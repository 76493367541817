import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "packsheet";
class PackSheetsService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }
  getPdfNames(sapCode: string) {
    return this.getAll<string[]>(
      `${CONTROLLER}/getpdfnames?sapCode=${sapCode}`
    );
  }

  getPdf(fileName: string) {
    return this.getAll<FileResponse>(
      `${CONTROLLER}/getpdf?fileName=${fileName}`
    );
  }
}

export const packSheetsService = new PackSheetsService();

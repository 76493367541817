<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar :title="$tc('sts.title')"></title-bar>
    </v-col>
    <v-col style="width: 450px">
      <select-row
        style="width: 450px"
        v-model="selectedDropdownItem"
        :inTitle="$tc('products.inputProducts')"
        :inItems="dropdownItems"
        hideTitle
        :in-place-holder="'SISTEMA DI CONTROLLO SALDATURA'"
      ></select-row>
    </v-col>
    <v-col style="width: 450px">
      <input-center
        v-model="MSACode"
        inPlaceHolder="INSERISCI CODICE MSA"
      ></input-center>
    </v-col>
    <v-col style="width: 270px">
      <v-btn
        class="mod--btn flex-grow-1"
        style="width: 100%"
        x-large
        @click="handleSearch"
      >
        {{ $tc("general.search") }}
      </v-btn>
    </v-col>
    <v-data-table
      v-if="tableDrawItemsObject.length"
      :headers="tableDrawHeaders"
      :items="tableDrawItemsObject"
      disable-pagination
      class="data-table elevation-1 header-bold"
      hide-default-footer
      @click:row="clickRow"
    >
      <template v-slot:[`item.value`]="{ item }">
        <td class="first-column">
          {{ item.value }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <td class="second-column">
          <v-btn text v-if="item" class="mod--btn_text">
            <v-icon left color="primary" class="ml-1" :size="38">
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { stsService } from "@/services/api/sts.service";
import { RequestSTSFileInterface } from "@/models/entities/sts/RequestSTSFile.interface";
import { VSelect } from "vuetify/lib/components";
import { DataTableHeader } from "vuetify";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Sts extends Vue {
  selectedDropdownItem = "";

  async created(): Promise<void> {
    await this.getDirectoryNames();
  }

  MSACode = "";
  tableDrawItems: string[] = [];
  tableDrawHeaders: DataTableHeader[] = [
    {
      text: this.$tc("stt.document"),
      align: "start",
      sortable: false,
      value: "value",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  dropdownItems: string[] = [];

  get tableDrawItemsObject(): Array<{ value: string }> {
    if (this.tableDrawItems && this.tableDrawItems.length > 0) {
      return this.tableDrawItems.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  async getDirectoryNames() {
    const response = await stsService.getDirectoryNames();
    this.dropdownItems = response.data;
  }

  clickRow(row: any): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        folderName: this.selectedDropdownItem,
        code: row.value,
        type: "STS",
      },
    });
    window.open(routeData.href, "_blank");
  }

  async handleSearch() {
    if (!this.MSACode || !this.selectedDropdownItem) {
      snackbarModule.appearSnackbar({
        message: this.$tc("sts.notAllowedSearch"),
        type: "error",
      });
      return;
    }
    overlayModule.showOverlay();
    const request: RequestSTSFileInterface = {
      folderName: this.selectedDropdownItem,
      msaCode: this.MSACode,
    };
    try {
      const response = await stsService.getPdfNames(request);
      this.tableDrawItems = response.data;
    } catch (e) {
      this.tableDrawItems = [];
    }
    overlayModule.hideOverlay();
  }
}
</script>
<style scoped>
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  margin-top: 12px;
}

.first-column {
  max-width: 200px !important;
  white-space: normal;
  overflow-wrap: break-word;
}

.second-column {
  width: 150px;
}
</style>

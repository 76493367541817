import axios from "axios";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "it",
  fallbackLocale: "en",
});

function setI18nLanguage(lang: string): string {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  /* eslint-disable */
  document.querySelector("html")!.setAttribute("lang", lang);
  /* eslint-enable */
  return lang;
}

export function loadLanguageAsync(lang: string): Promise<string> {
  if (process.env.NODE_ENV === "production") {
    return fetch(`/i18n/config/${lang}-${lang.toUpperCase()}.json`)
      .then((r) => r.json())
      .then((msgs) => {
        i18n.setLocaleMessage(lang, msgs);
        return setI18nLanguage(lang);
      });
  } else {
    return import(
      /* webpackChunkName: "lang-[request]" */ `@/i18n/lang/${lang}-${lang.toUpperCase()}.json`
    ).then((msgs) => {
      i18n.setLocaleMessage(lang, msgs.default);
      return setI18nLanguage(lang);
    });
  }
}

const translate = (
  key: string,
  choice?: number,
  values?: unknown[] | Record<string, unknown>
): string => {
  if (!key) return "";
  if (Array.isArray(values)) return i18n.tc(key, choice, values);
  else return i18n.tc(key, choice, values);
};

await loadLanguageAsync("it");
export { translate };

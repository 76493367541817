var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-container"},[_c('v-col',{staticClass:"pb-12",attrs:{"cols":"12"}},[_c('title-bar',{attrs:{"title":_vm.$tc('manuals.title'),"is-parent-element":false}})],1),_c('v-col',{staticStyle:{"width":"450px"}},[_c('select-row',{staticStyle:{"width":"450px"},attrs:{"inTitle":_vm.$tc('manuals.dropdownTitle'),"inItems":_vm.departments,"hideTitle":"","in-place-holder":_vm.$tc('manuals.dropdownTitle'),"return-object":true,"item-text":'denominazione',"item-value":'codreparto'},model:{value:(_vm.selectedDepartment),callback:function ($$v) {_vm.selectedDepartment=$$v},expression:"selectedDepartment"}})],1),_c('v-col',{staticStyle:{"width":"450px"}},[_c('input-center',{attrs:{"inPlaceHolder":_vm.$tc('manuals.locationTextFiledTitle'),"type":"number"},on:{"submitSearch":_vm.submitSearch},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),(_vm.btnVisible)?_c('v-row',{staticClass:"pt-10",attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"width":"270px"},attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mod--btn flex-grow-1",staticStyle:{"width":"100%"},attrs:{"x-large":""},on:{"click":_vm.submitSearch}},[_vm._v(" "+_vm._s(_vm.$tc("general.search"))+" ")])],1)],1):_vm._e(),(_vm.assetList.length && _vm.selectedDatatable === 1)?_c('v-row',{staticClass:"mod--table_row"},[_c('v-col',{staticClass:"px-3"},[_c('v-data-table',{staticClass:"elevation-1 header-bold brack-world-custom",attrs:{"id":"assets-table","headers":_vm.assetsTableHeaders,"items":_vm.assetsFiltered,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":_vm.btnVisible
            ? _vm.windowHeight < 900
              ? 'calc(100vh - 500px)'
              : 'calc(100vh - 720px)'
            : 'calc(100vh - 400px)'},on:{"click:row":_vm.clickAsset},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(item)?_c('v-btn',{staticClass:"mod--btn_text",attrs:{"text":""}},[_c('v-icon',{staticClass:"ml-1",attrs:{"left":"","color":"primary","size":38}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()]}}],null,true)})],1)],1):_vm._e(),(_vm.foldersByAsset.length > 1 && _vm.selectedDatatable === 2)?_c('v-row',{staticClass:"mod--table_row"},[_c('v-col',{staticClass:"px-3"},[_c('div',{staticClass:"back-button",on:{"click":_vm.backToAssets}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v(_vm._s(_vm.$tc("manuals.backToAssets")))])],1),_c('v-data-table',{staticClass:"elevation-1 header-bold brack-world-custom",attrs:{"id":"folder-table","headers":_vm.folderTableHeader,"items":_vm.folderNamesFiltered,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":_vm.btnVisible
            ? _vm.windowHeight < 900
              ? 'calc(100vh - 500px)'
              : 'calc(100vh - 720px)'
            : 'calc(100vh - 400px)'},on:{"click:row":_vm.clickFolder},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(item)?_c('v-btn',{staticClass:"mod--btn_text",attrs:{"text":""}},[_c('v-icon',{staticClass:"ml-1",attrs:{"left":"","color":"primary","size":38}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()]}}],null,true)})],1)],1):_vm._e(),(_vm.documentsNames.length && _vm.selectedDatatable === 3)?_c('v-row',{staticClass:"mod--table_row"},[_c('v-col',{staticClass:"px-3"},[_c('div',{staticClass:"back-button",on:{"click":_vm.backToAssets}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v(_vm._s(_vm.$tc("manuals.backToAssets")))])],1),_c('v-data-table',{staticClass:"elevation-1 header-bold brack-world-custom",attrs:{"id":"documents-table","headers":_vm.documentsTableHeaders,"items":_vm.documentsNamesFiltered,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":_vm.btnVisible
            ? _vm.windowHeight < 900
              ? 'calc(100vh - 500px)'
              : 'calc(100vh - 720px)'
            : 'calc(100vh - 400px)'},on:{"click:row":_vm.clickDocument},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(item)?_c('v-btn',{staticClass:"mod--btn_text",attrs:{"text":""}},[_c('v-icon',{staticClass:"ml-1",attrs:{"left":"","color":"primary","size":38}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app id="app">
    <!-- HEADER -->
    <v-app-bar
      v-if="
        pageName != 'login' &&
        pageName != 'equipmentDetails' &&
        pageName != 'PdfOpener'
      "
      color="white"
      app
      :height="105"
      class="mod--shadow_appbar"
    >
      <v-col style="padding: 0">
        <v-row style="margin: 0; align-items: center; width: 100%">
          <div class="px-0" style="display: flex">
            <v-img
              :alt="$tc('alt.logoDigita')"
              class="shrink mx-auto"
              contain
              :src="require('../src/assets/msa-digita-logo.png')"
              transition="scale-transition"
              width="250px"
              max-width="250px"
            />
          </div>
          <span v-if="isProductionTest" style="font-size: 42px; color: red">
            TEST
          </span>
          <v-img
            :src="require('@/assets/PRIMO SOCCORSO.png')"
            max-height="115px"
            max-width="115px"
            @click="openFile('SECCHART')"
          />
          <v-img
            :src="require('@/assets/REFERENTI EMERGENZE.png')"
            max-height="115px"
            max-width="115px"
            @click="openFile('EMERGENCY_CONTACTS')"
          />
          <v-img
            :src="require('@/assets/calcolatrice.png')"
            max-height="115px"
            max-width="115px"
            @click="showCalculator = true"
          />
          <v-spacer></v-spacer>
          <span class="ma-0 px-0 text-right mod--header">
            {{ isOrientationPortrait ? null : dataString }} &nbsp;&nbsp;
            {{ dataString2 }}
          </span>
        </v-row>
      </v-col>
    </v-app-bar>

    <!-- MAIN -->
    <v-main
      :class="{
        'main-content-home': pageName === 'home',
        classByPageName,
        themeColor,
      }"
    >
      <router-view />
    </v-main>

    <calculator
      v-if="showCalculator"
      v-model="showCalculator"
      @click:outside="showCalculator = false"
    ></calculator>

    <!-- SNACKBAR -->
    <v-snackbar
      :timeout="-1"
      :value="snackbar"
      bottom
      right
      elevation="24"
      class="message-snackbar mod--snackbar"
      :color="'var(--v-' + type + '-lighten2)'"
    >
      <div class="d-flex">
        <v-icon
          :color="type + ' darken2'"
          v-if="icon"
          :size="38"
          class="mod--icon mr-2"
        >
          {{ icon }}
        </v-icon>
        <span :class="type + '--text'">{{ message }}</span>
      </div>
    </v-snackbar>

    <!--    LOADING OVERLAY-->
    <v-overlay :value="overlayVisible" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- FOOTER -->
    <v-footer
      v-if="pageName == 'login'"
      flat
      color="white"
      class="justify-center"
    >
      <span>{{
        $tc("general.footerText", 1, { year: new Date().getFullYear() })
      }}</span>
    </v-footer>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { custom } from "@/helpers/customMethods";
import store from "./store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import "@fontsource/public-sans";
import update from "./mixins/update.js";
import { userHasAuth } from "@/helpers/auths";
import Calculator from "@/components/AppBar/Calculator.vue";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackBarModule = getModule(SnackbarModule, store);

@Component({
  components: { Calculator },
  mixins: [update],
})
export default class App extends Vue {
  themeColor = "col_base";
  pageName: string | null | undefined = "";
  dataString = "";
  dataString2 = "";
  showCalculator = false;

  get snackbar(): boolean {
    return snackBarModule.status;
  }

  get isProductionTest(): boolean {
    return (
      process.env.NODE_ENV === "production" &&
      (process.env.VUE_APP_IS_PRODUCTION_TEST === 1 ||
        process.env.VUE_APP_IS_PRODUCTION_TEST === "1")
    );
  }

  get message(): string {
    return snackBarModule.message;
  }

  get type(): string {
    return snackBarModule.type;
  }

  get classByPageName(): string {
    switch (this.pageName) {
      case "login":
      case "home":
        return "login-bg-img pa-4 pb-12";
      case "products":
        return "products-bg-img pa-4 pb-12";
      case "equipment":
        return "equipment-bg-img pa-4 pb-12";
      case "mp":
        return "mp-bg-img pa-4 pb-12";
      case "datasheetList":
        return "datasheetList-bg-img pa-4 pb-12";
      case "msa":
        return "msa-bg-img pa-4 pb-12";
      case "stt":
        return "stt-bg-img pa-4 pb-12";
      case "sts":
        return "sts-bg-img pa-4 pb-12";
      case "manuals":
        return "manuals-bg-img pa-4 pb-12";
      case "maintenance":
        return "maintenance-bg-img pa-4 pb-12";
      case "smallMaintenance":
        //TODO: RIPRISTINARE SFONDO COMMENTATO
        return "login-bg-img pa-4 pb-12";
      // return "small-maintenance-bg-img pa-4 pb-12";
      case "utility":
        return "utility-bg-img pa-4 pb-12";
      case "rubric":
        return "rubric-bg-img pa-4 pb-12";
      case "equipmentManagement":
        return "equipmentManagement-bg-img pa-4 pb-12";
      case "activePartsAndNormalized":
        return "activeParts-bg-img pa-4 pb-12";
      case "activeParts":
        return "activeParts-bg-img pa-4 pb-12";
      case "normalized":
        return "activeParts-bg-img pa-4 pb-12";
      default:
        return "none";
    }
  }

  get icon(): string {
    return this.type === "warning"
      ? "mdi-information-outline"
      : this.type === "error"
      ? "mdi-alert-circle-outline"
      : this.type === "success"
      ? "mdi-check-circle-outline"
      : "";
  }

  get overlayVisible(): boolean {
    return overlayModule.status;
  }

  get isOrientationPortrait(): boolean {
    return authModule.isOrientationPortrait;
  }

  destroyed() {
    window.removeEventListener("resize", this.checkOrientation);
  }

  created() {
    window.addEventListener("resize", this.checkOrientation);
    if (overlayModule.status === true) overlayModule.hideOverlay();
    this.changeTheme();
    var data = custom.getHeaderDate().split(".");
    this.dataString = data[0];
    this.dataString2 = data[1];
    setInterval(() => {
      data = custom.getHeaderDate().split(".");
      this.dataString = data[0];
      this.dataString2 = data[1];
    }, 1000);
  }

  @Watch("$route")
  changeTheme(): void {
    this.pageName = this.$route.name;
    switch (this.$route.name) {
      case "login":
        break;
      case "msa":
        this.themeColor = "col_MSA";
        break;
      case "equipment":
        this.themeColor = "col_equipment";
        break;
      case "mp":
        this.themeColor = "col_MP";
        break;
      case "products":
        this.themeColor = "col_products";
        break;
      case "qualityCheck":
        this.themeColor = "col_products";
        break;
      case "smallMaintenance":
        this.themeColor = "col_smallMnt";
        break;
      case "manuals":
        this.themeColor = "col_manMnt";
        break;
      default:
        this.themeColor = "mod--color_base";
        break;
    }
  }

  checkOrientation(): void {
    const orientation = window.screen.orientation.type;
    authModule.setOrientationPortrait(orientation === "portrait-primary");
  }

  openFile(authId: string) {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { type: authId },
    });
    window.open(routeData.href, "_blank");
    return;
  }
}
</script>
<style lang="scss">
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-spreadsheet/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";

.main-content-home {
  height: calc(100svh - 105px - 100px);
  overflow-y: auto;
  margin-bottom: 100px;
}

.main-content {
  height: calc(100svh - 105px);
  overflow-y: auto;
}

.main-content {
  flex: 1; /* Consente al contenuto di occupare lo spazio disponibile */
}

.v-overlay {
  position: absolute;
}

body {
  font-family: "Public Sans";
}

.btn-order > .v-btn__content {
  flex: auto;
  white-space: break-spaces;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // Default sizes:
  $h: 96px;
  $h_logout: 80px;
  $r: 24px;
  $r_msa: 32px;
  $d_check: 60px;
  $d_check_text: 90px;
  $sel_p: 28px;
  $p_table_head: 16px;
  $p_table_body: 28px;
  $p_logout: 40px;
  $w_logout: 320px;

  // Font sizes:
  $t: 26px;
  $t_header: 26px;
  $t_btn: 23px;
  $t_h1: 30px;
  $t_h2: 36px;
  $t_strong: 33px;
  $t_snackbar_lineheight: 38px;

  // Font weights:
  $w_regular: 500;
  $w_bold: 900;

  // Colors:
  $color_btn: #424242;

  // Shadows:
  $shadow_btn: 0px 4px 8px rgba(0, 0, 0, 0.18);
  $shadow_sel: 0px 2px 4px rgba(0, 0, 0, 0.18);
  $shadow_appbar: 0px 6px 12px rgba(0, 0, 0, 0.2);

  // Gradients:
  $bg_app: 180deg, #ffffff 0%, #e3e4e6 100%;
  $bg_btn_primary: 180deg, #2a67b5 0%, #003b86 100%;

  // Msa buttons style:
  $shadow_btn_msa: 0px 10px 16px rgba(0, 0, 0, 0.2),
    inset 0px -2px 10px rgba(0, 0, 0, 0.12),
    inset 0px 2px 4px rgba(255, 255, 255, 0.63),
    inset 0px -21px 27px rgba(29, 6, 6, 0.14),
    inset 0px 26px 34px rgba(255, 255, 255, 0.18);
  $shadow_btn_msa_active: inset 0px -8px 15px rgba(0, 0, 0, 0.1),
    inset 0px 2px 10px rgba(0, 0, 0, 0.3),
    inset 0px 22px 27px rgba(29, 6, 6, 0.1),
    inset 0px -26px 34px rgba(255, 255, 255, 0.05);
  $r_msa: 32px;

  font-size: $t;

  // Table:
  .v-data-table {
    &.theme--light {
      background: #ffffff99;

      tbody,
      .v-data-footer {
        background: #ffffff;
      }
    }

    &.theme--dark {
      background: #00000099;

      tbody,
      .v-data-footer {
        background: #000000;
      }
    }

    & > .v-data-table__wrapper > table > thead > tr > th {
      padding-top: $p_table_head;
      padding-bottom: $p_table_head;
      font-size: $t_btn;
      font-weight: $w_regular;
      color: #000;
    }

    & > .v-data-table__wrapper > table > tbody > tr > td {
      padding-top: $p_table_head;
      padding-bottom: $p_table_head;
      font-size: $t;
      color: #000;
    }
  }

  // Table Admin:
  .v-data-table.mod--table_admin {
    // THIS IS HEADER BG
    background: transparent;

    tbody,
    .v-data-footer {
      background: transparent;
    }

    .v-data-table-header {
      background: #e0e0e0;
    }
  }

  // Select styling (outside of v-select tag):
  @media screen and (max-width: 992px) {
    .v-list-item {
      min-height: $h;

      .v-list-item__title {
        font-size: $t_btn;
      }
    }
  }

  // Misc modifiers:
  .mod-- {
    // App gradients
    &color_ {
      &base {
        background: linear-gradient(#{$bg_app});
      }

      &btn_ {
        // Button background
        $bg_btn_sap: 180deg, #75d0f2 0%, #5dacf5 100%;
        $bg_btn_sap_inv: 360deg, #75d0f2 0%, #5dacf5 89.22%;
        $bg_btn_msa: 180deg, #fd8888 0%, #fe718b 100%;
        $bg_btn_stain: 180deg, #7885fe 0%, #1f59b1 100%;
        $bg_btn_hse: 180deg, #ffffff 0%, #dce0d9 100%;
        $bg_btn_bo: 180deg, #75d0f2 0%, #12a7c1 100%;
        $bg_btn_manmnt: 180deg, #eb5f76 100%, #ff604e 100%;
        $bg_btn_smallmnt: #f43948, #f4232c, #f10301;
        $bg_btn_activeParts: #4debbc, #1ab889, #009e6f;
        $bg_btn_rubric: #ffa74e, #ff8d34, #f8741b;
        $bg_btn_web: #ffffff, #e3e3e3;
        $bg_btn_msa_inv: 360deg, #fca7a2 0%, #eb5f76 100%;
        $bg_btn_equipment: 180deg, #a0b5fe 21.88%, #7885fe 100%;
        $bg_btn_equipment_inv: 0deg, #aabcff 0%, #4b58e1 100%;
        $bg_btn_mp: 180deg, #5e99b3 0%, #114c66 100%;
        $bg_btn_mp_inv: 360deg, #5e99b3 0%, #114c66 100%;
        $bg_btn_products: 180deg, #4db7e5 0%, #006a98 100%;
        $bg_btn_products_inv: 0deg, #4db7e5 0%, #006a98 100%;
        $bg_btn_sat: 180deg, #5dd8ff 0%, #2aa5ff 100%;
        $bg_btn_sat_inv: 0deg, #5dd8ff 0%, #2aa5ff 100%;
        $bg_btn_stt: 180deg, #74c4ff 0%, #4191ff 100%;
        $bg_btn_stt_inv: 0deg, #74c4ff 0%, #4191ff 100%;
        $bg_btn_sts: 180deg, #7d9fff 0%, #4a6cff 100%;
        $bg_btn_sts_inv: 0deg, #7d9fff 0%, #4a6cff 100%;
        $bg_btn_management: 180deg, #eebdff 0%, #e8a5ff 100%;
        $bg_btn_management_inv: 0deg, #eebdff 0%, #e8a5ff 100%;

        &sap {
          background: linear-gradient(#{$bg_btn_sap});

          &:active {
            background: linear-gradient(#{$bg_btn_sap_inv});
          }
        }

        &msa {
          background: linear-gradient(#{$bg_btn_msa});

          &:active {
            background: linear-gradient(#{$bg_btn_msa_inv});
          }
        }

        &stain {
          background: linear-gradient(#{$bg_btn_stain});

          &:active {
            background: linear-gradient(#{$bg_btn_msa_inv});
          }
        }

        &hse {
          background: linear-gradient(#{$bg_btn_hse});

          &:active {
            background: linear-gradient(#{$bg_btn_msa_inv});
          }
        }

        &bo {
          background: linear-gradient(#{$bg_btn_bo});

          &:active {
            background: linear-gradient(#{$bg_btn_msa_inv});
          }
        }

        &activeParts {
          background: linear-gradient(#{$bg_btn_activeParts});

          &:active {
            background: linear-gradient(#{$bg_btn_activeParts});
          }
        }

        &manmnt {
          background: linear-gradient(#{$bg_btn_manmnt});

          &:active {
            background: linear-gradient(#{$bg_btn_msa_inv});
          }
        }

        &smallmnt {
          background: linear-gradient(#{$bg_btn_smallmnt});

          &:active {
            background: linear-gradient(#{$bg_btn_msa_inv});
          }
        }

        &rubric {
          background: linear-gradient(#{$bg_btn_rubric});

          &:active {
            background: linear-gradient(#{$bg_btn_rubric});
          }
        }

        &web {
          background: linear-gradient(#{$bg_btn_web});

          &:active {
            background: linear-gradient(#{$bg_btn_web});
          }
        }

        &equipment {
          background: linear-gradient(#{$bg_btn_equipment});

          &:active {
            background: linear-gradient(#{$bg_btn_equipment_inv});
          }
        }

        &mp {
          background: linear-gradient(#{$bg_btn_mp});

          &:active {
            background: linear-gradient(#{$bg_btn_mp_inv});
          }
        }

        &products {
          background: linear-gradient(#{$bg_btn_products});

          &:active {
            background: linear-gradient(#{$bg_btn_products_inv});
          }
        }

        &sat {
          background: linear-gradient(#{$bg_btn_sat});

          &:active {
            background: linear-gradient(#{$bg_btn_sat_inv});
          }
        }

        &stt {
          background: linear-gradient(#{$bg_btn_stt});

          &:active {
            background: linear-gradient(#{$bg_btn_stt_inv});
          }
        }

        &sts {
          background: linear-gradient(#{$bg_btn_sts});

          &:active {
            background: linear-gradient(#{$bg_btn_sts_inv});
          }
        }

        &management {
          background: linear-gradient(#{$bg_btn_management});

          &:active {
            background: linear-gradient(#{$bg_btn_management_inv});
          }
        }
      }
    }

    &fill_height {
      height: 100%;
      min-height: 100%;
    }

    &table_row {
      margin-left: -15px;
      margin-right: -15px;
    }

    &btn {
      min-height: $h;
      border-radius: $r;
      font-size: $t_btn;
      letter-spacing: 0.01em;
      color: #{$color_btn};
      box-shadow: #{$shadow_btn};
      background: linear-gradient(#{$bg_app});

      &_logout {
        box-shadow: none;
        min-height: $h_logout;
      }

      &_block {
        min-width: 100%;
      }

      &_primary {
        background: linear-gradient(#{$bg_btn_primary});
      }

      &_msa {
        padding: 0 10px;
        white-space: normal;
        overflow-wrap: anywhere;
        text-align: center;
        border-radius: $r_msa;
        transition: background 0.5s, box-shadow 0.5s;
        box-shadow: #{$shadow_btn_msa};
        min-height: 100%;
        width: 100%;

        &:active {
          box-shadow: #{$shadow_btn_msa_active};
        }

        .v-ripple__container {
          display: none;
        }

        .v-btn__content {
          flex: 1;
        }
      }

      &_text {
        font-size: $t_btn;
      }
    }

    &strong {
      font-size: $t_strong;
    }

    &header {
      font-size: $t_header;
    }

    &h1 {
      font-size: $t_h1;
      font-weight: $w_bold;
    }

    &h2 {
      font-size: $t_h2;
      font-weight: $w_bold;
    }

    &icon {
      margin-left: 0;
      margin-right: 0;
    }

    &home_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: $r_msa;
      row-gap: $r_msa;

      &--landscape {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }

    &select {
      border-radius: $r;
      @media screen and (max-width: 992px) {
        font-size: $t_btn;
      }
      box-shadow: #{$shadow_sel};

      .v-input__append-inner {
        margin: 0;
        align-self: center;
      }

      .v-select__selection--comma {
        overflow: hidden;
      }
    }

    &input {
      border-radius: $r;
      font-size: $t_btn;
      box-shadow: #{$shadow_sel};

      .v-input__slot {
        padding-left: $sel_p;
        padding-right: $sel_p;
      }

      .v-text-field__slot {
        input,
        label {
          font-size: $t_btn;
        }
      }
    }

    &check {
      // background
      @media screen and (max-width: 992px) {
        .v-icon.v-icon {
          font-size: $d_check_text;
          background: #ffffff;
        }

        // non checked style
        .mdi-checkbox-blank-outline::before {
          display: block;
          background: transparent;
          font-size: $d_check_text;
          color: #ffffff;
        }

        .v-input--selection-controls {
          &__input {
            height: $d_check;
            min-width: $d_check;
            box-shadow: #{$shadow_sel};
          }

          &__ripple {
            display: none;
          }
        }
      }

      .v-messages {
        display: none;
      }
    }

    &shadow_appbar {
      box-shadow: #{$shadow_appbar};
    }

    &snackbar {
      .v-snack__content {
        font-size: $t_btn;
        line-height: $t_snackbar_lineheight;
      }
    }

    &chip {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .login-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
    background-size: 100%;
  }

  .products-bg-img {
    background-image: url("@/assets/background/sfondo schermate MSA-9.jpg") !important;
    background-size: 100%;
  }

  .manuals-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate_06.jpg") !important;
    background-size: 100%;
  }

  .small-maintenance-bg-img {
    background-image: url("@/assets/background/Sfondo schermate_07.jpg") !important;
    background-size: 100%;
  }

  .utility-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
    background-size: 100%;
  }

  .rubric-bg-img {
    background-image: url("@/assets/background/Sfondo schermate-08.jpg") !important;
    background-size: 100%;
  }

  .activeParts-bg-img {
    background-image: url("@/assets/background/Sfondo schermate-09.jpg") !important;
    background-size: 100%;
  }

  .equipmentManagement-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
    background-size: 100%;
  }

  .maintenance-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
    background-size: 100%;
  }

  .qualityCheck-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
    background-size: 100%;
  }

  .datasheetList-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
    background-size: 100%;
  }

  .equipment-bg-img {
    background-image: url("@/assets/background/Sfondo schermate-5.jpg") !important;
    background-size: 100%;
  }

  .mp-bg-img {
    background-image: url("@/assets/background/sfondo schermate MSA-8.jpg") !important;
    background-size: 100%;
  }

  .msa-bg-img {
    background-image: url("@/assets/background/Sfondo_schermate-01.jpg") !important;
    background-size: 100%;
  }

  .stt-bg-img {
    background-image: url("@/assets/background/Sfondo schermate-6.jpg") !important;
    background-size: 100%;
  }

  .sts-bg-img {
    background-image: url("@/assets/background/Sfondo schermate-7.jpg") !important;
    background-size: 100%;
  }

  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #ccc;
  }

  .progress {
    height: 100%;
    background-color: #007bff; /* Colore di riempimento */
    transition: width 1s linear; /* Animazione */
  }

  //SGQ styles
  .sgq-- {
    &position- {
      &absolute {
        position: absolute;

        &.top {
          top: 11%;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('software.Title')"
        :is-parent-element="true"
      ></title-bar>
    </v-col>
    <div
      :class="
        isOrientationPortrait
          ? 'button-grid-vertical'
          : 'button-grid-horizontal'
      "
    >
      <div
        v-for="(btn, i) in buttonListEnabled"
        :key="btn.id"
        class="grid-item"
        @click="goToPage(btn.r, btn.id)"
      >
        <img
          :key="i"
          style="width: 100%"
          :src="
            btn.id == 'STAIN'
              ? require('@/assets/DASHBOARD STAIN.png')
              : btn.id == 'HSE'
              ? require('@/assets/HSE.png')
              : btn.id == 'WEB'
              ? require('@/assets/STUDIOWEB.png')
              : btn.id == 'BO'
              ? require('@/assets/BUSINESS OBJECT.png')
              : ''
          "
          alt="icon"
        />
        <span class="grid-item-text">{{ $tc(btn.t) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { rapidLinkService } from "@/services/api/rapidLink.service";
import { RapidLinkEnum } from "@/models/enumerations/rapidLinkEnum";
import { buttonSortService } from "@/services/api/buttonSort.service";
import OverlayModule from "@/store/modules/overlay.module";
import { HomeButton } from "@/models/entities/components/components.interface";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component({})
export default class Software extends Vue {
  buttonListEnabled: HomeButton[] = [];
  buttonList: HomeButton[] = [
    {
      id: "HSE",
      r: "",
      t: "home.buttonHSE",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "HSE";
        }).length > 0,
      sort: 0,
    },
    {
      id: "BO",
      r: "",
      t: "home.buttonBO",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "BO";
        }).length > 0,
      sort: 0,
    },
    {
      id: "STAIN",
      r: "",
      t: "home.buttonSTAIN",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "STAIN";
        }).length > 0,
      sort: 0,
    },
    {
      id: "WEB",
      r: "",
      t: "home.buttonWEB",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "WEB";
        }).length > 0,
      sort: 0,
    },
  ];

  get isOrientationPortrait() {
    return authModule.isOrientationPortrait;
  }

  async created(): Promise<void> {
    overlayModule.showOverlay();
    const response = await buttonSortService.getAuthsByParent("SOFTWARE");

    this.buttonListEnabled = this.buttonList.filter((value) =>
      response.data.includes(value.id)
    );
    overlayModule.hideOverlay();
  }

  isRapidLink(authId: string): boolean {
    return ["HSE", "STAIN", "BO", "WEB"].includes(authId);
  }

  async goToPage(path: string, id: string): Promise<void> {
    if (this.isRapidLink(id)) {
      const response = await rapidLinkService.getLinkByAuth(RapidLinkEnum[id]);
      window.open(response.data, "_blank");
    } else {
      await this.$router.replace({ path: path });
    }
  }
}
</script>

<style scoped>
.button-grid-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.button-grid-vertical {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-item {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.grid-item-text {
  font-weight: bold;
  text-align: center;
}
</style>

<template>
  <div
    :style="{
      background: isOrderButtonsOn ? '#cccccc' : '',
      padding: 0,
    }"
    ref="orientationDiv"
  >
    <draggable
      v-if="isOrderButtonsOn"
      v-model="buttonListEnabled"
      group="buttons"
      @start="drag = true"
      @end="drag = false"
      :sort="isOrderButtonsOn"
      :class="
        isOrientationPortrait
          ? 'button-grid-vertical'
          : 'button-grid-horizontal'
      "
      handle=".drag-handle"
    >
      <div
        v-for="(btn, i) in buttonListEnabled"
        :key="btn.id"
        class="grid-item"
      >
        <img
          alt="icon"
          :key="i"
          style="width: 100%"
          class="drag-handle"
          :src="
            btn.id == 'MSA'
              ? require('@/assets/msa.svg')
              : btn.id == 'SAP'
              ? require('@/assets/sap.svg')
              : btn.id == 'USR'
              ? require('@/assets/usr.svg')
              : btn.id == 'SGQ'
              ? require('@/assets/SGQ.png')
              : btn.id == 'ST'
              ? require('@/assets/Schede tecniche.png')
              : btn.id == 'MNT'
              ? require('@/assets/MANUTENZIONE.png')
              : btn.id == 'UTILITY'
              ? require('@/assets/UTILITY.png')
              : btn.id == 'EQPMNG'
              ? require('@/assets/GESTIONE ATTREZZATURE.png')
              : btn.id == 'SOFTWARE'
              ? require('@/assets/SOFTWARE.png')
              : btn.id == 'HANDOVER'
              ? require('@/assets/HANDOVER.png')
              : ''
          "
        />
        <span class="grid-item-text">
          {{ $tc(btn.t) }}
        </span>
      </div>
    </draggable>
    <div
      v-if="!isOrderButtonsOn"
      :class="
        isOrientationPortrait
          ? 'button-grid-vertical'
          : 'button-grid-horizontal'
      "
    >
      <div
        v-for="(btn, i) in buttonListEnabled"
        :key="btn.id"
        class="grid-item"
        @click="goToPage(btn.r, btn.id)"
      >
        <img
          :key="i"
          style="width: 100%"
          :src="
            btn.id == 'MSA'
              ? require('@/assets/msa.svg')
              : btn.id == 'SAP'
              ? require('@/assets/sap.svg')
              : btn.id == 'USR'
              ? require('@/assets/usr.svg')
              : btn.id == 'SGQ'
              ? require('@/assets/SGQ.png')
              : btn.id == 'ST'
              ? require('@/assets/Schede tecniche.png')
              : btn.id == 'MNT'
              ? require('@/assets/MANUTENZIONE.png')
              : btn.id == 'UTILITY'
              ? require('@/assets/UTILITY.png')
              : btn.id == 'EQPMNG'
              ? require('@/assets/GESTIONE ATTREZZATURE.png')
              : btn.id == 'SOFTWARE'
              ? require('@/assets/SOFTWARE.png')
              : btn.id == 'HANDOVER'
              ? require('@/assets/HANDOVER.png')
              : ''
          "
          alt="icon"
        />
        <span class="grid-item-text">{{ $tc(btn.t) }}</span>
      </div>
    </div>

    <v-footer color="col_base" class="flex-space-between" app :height="100">
      <v-btn
        outlined
        class="mod--btn mr-3"
        style="min-height: 50px; min-width: 50px; border-radius: 12px"
        @click="goChangePassword"
      >
        <v-icon left :size="30" class="mod--icon">mdi-cog-outline</v-icon>
      </v-btn>
      <p class="my-4" v-if="footerUserName">
        <span>{{ footerUserName }}</span>
      </p>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isOrderButtonsOn"
        outlined
        class="mod--btn mod--btn_logout mr-2 btn-order"
        style="width: 220px; font-size: 22px"
        @click="restoreButtonSort"
      >
        <span>
          {{ $tc("home.ButtonRestoreSort") }}
        </span>
      </v-btn>
      <v-btn
        outlined
        class="mod--btn mod--btn_logout mr-2 btn-order"
        style="width: 160px; font-size: 22px"
        @click="changeOrderButtonAction"
      >
        <span>
          {{
            isOrderButtonsOn
              ? $tc("home.buttonSortOff")
              : $tc("home.buttonSortOn")
          }}
        </span>
      </v-btn>
      <v-btn
        outlined
        class="mod--btn"
        style="min-height: 80px"
        @click="submitLogout"
      >
        <v-icon left :size="38" class="mod--icon"> mdi-logout</v-icon>
        <span v-if="!$vuetify.breakpoint.mdAndDown" class="ml-4">
          {{ $tc("general.logout") }}
        </span>
      </v-btn>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { buttonSortService } from "@/services/api/buttonSort.service";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { HomeButton } from "@/models/entities/components/components.interface";
import { InsertItem } from "@/models/entities/buttonSort/buttonSort.interface";
import { rapidLinkService } from "@/services/api/rapidLink.service";
import { RapidLinkEnum } from "@/models/enumerations/rapidLinkEnum";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class HomePage extends Vue {
  isOrderButtonsOn = false;

  buttonST = {
    id: "ST",
    r: "/datasheetList",
    t: "home.buttonST",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonUtility = {
    id: "UTILITY",
    r: "/utility",
    t: "home.buttonUTILITY",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonMNT = {
    id: "MNT",
    r: "/maintenance",
    t: "home.buttonMNT",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonEQPMNG = {
    id: "EQPMNG",
    r: "/equipmentmanagement",
    t: "home.buttonEQPMNG",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonSOFTWARE = {
    id: "SOFTWARE",
    r: "/software",
    t: "home.buttonSOFTWARE",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };

  buttonList: HomeButton[] = [
    {
      id: "SAP",
      r: "",
      t: "home.buttonSAP",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SAP";
        }).length > 0,
      sort: 0,
    },
    {
      id: "MSA",
      r: "/msa",
      t: "home.buttonMSA",
      c: "mod--color_btn_msa",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "MSA";
        }).length > 0,
      sort: 0,
    },
    {
      id: "USR",
      r: "/userManagement",
      t: "home.buttonUSR",
      c: "mod--color_btn_management",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "USR";
        }).length > 0,
      sort: 0,
    },
    {
      id: "SGQ",
      r: "/theseus",
      t: "home.buttonSGQ",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SGQ";
        }).length > 0,
      sort: 0,
    },
    {
      id: "HANDOVER",
      r: "/handover",
      t: "home.buttonHANDOVER",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "HANDOVER";
        }).length > 0,
      sort: 0,
    },
  ];
  buttonListEnabled: HomeButton[] = [];
  isOrientationPortrait = true;
  file = null;
  footerUserName = authModule.userDescription;

  isPresentDatasheetAuth(authId: string): boolean {
    return ["SAT", "SDS", "SMP", "STS", "STT"].includes(authId);
  }

  isPresentMaintenanceAuth(authId: string): boolean {
    return ["SMALLMNT", "MANMNT"].includes(authId);
  }

  isPresentSoftwareAuth(authId: string): boolean {
    return ["STAIN", "HSE", "WEB", "BO"].includes(authId);
  }

  isPresentUtilityAuth(authId: string): boolean {
    return ["RUBRIC"].includes(authId);
  }

  isPresentEquipmentManagementAuth(authId: string): boolean {
    return ["ACTIVEPARTS"].includes(authId);
  }

  mounted(): void {
    this.checkOrientation();
    this.reloadButtonOrder();
  }

  isRapidLink(authId: string): boolean {
    return ["SAP"].includes(authId);
  }

  async goToPage(path: string, id: string) {
    if (id === "SGQ") {
      window.open(path, "_blank");
    }
    if (this.isRapidLink(id)) {
      const response = await rapidLinkService.getLinkByAuth(RapidLinkEnum[id]);
      window.open(response.data, "_blank");
    } else {
      await this.$router.replace({ path: path });
    }
  }

  checkOrientation(): void {
    const orientation = window.screen.orientation.type;
    this.isOrientationPortrait = orientation === "portrait-primary";
  }

  async reloadButtonOrder(): Promise<void> {
    overlayModule.showOverlay();
    let btnTrueInsert = this.buttonList.filter((x) => {
      return x.enabled === true;
    });
    try {
      const response = await buttonSortService.getOrderedAuths();

      let btnList: HomeButton[] = [];
      for (const btn of response.data) {
        let btnInsert = btnTrueInsert.filter((x) => {
          return x.id === btn.authId;
        });
        if (btnInsert && btnInsert.length > 0) {
          btnInsert[0].sort = btn.sort;
          if (
            btn.authId !== "ST" &&
            btn.authId !== "MNT" &&
            btn.authId !== "UTILITY" &&
            btn.authId !== "EQPMNG"
          ) {
            btnList.push(btnInsert[0]);
          }
        }
        if (this.isPresentDatasheetAuth(btn.authId)) {
          const buttonSTSort = response.data.find(
            (el) => el.authId === "ST"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonST)) {
            this.buttonST.sort = buttonSTSort;
            btnList.push(this.buttonST);
          }
        }
        if (this.isPresentMaintenanceAuth(btn.authId)) {
          const buttonMNTSort = response.data.find(
            (el) => el.authId === "MNT"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonMNT)) {
            this.buttonMNT.sort = buttonMNTSort;
            btnList.push(this.buttonMNT);
          }
        }
        if (this.isPresentSoftwareAuth(btn.authId)) {
          const buttonSoftwareSort = response.data.find(
            (el) => el.authId === "SOFTWARE"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonSOFTWARE)) {
            this.buttonSOFTWARE.sort = buttonSoftwareSort;
            btnList.push(this.buttonSOFTWARE);
          }
        }
        if (this.isPresentUtilityAuth(btn.authId)) {
          const buttonSTSort = response.data.find(
            (el) => el.authId === "UTILITY"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonUtility)) {
            this.buttonUtility.sort = buttonSTSort;
            btnList.push(this.buttonUtility);
          }
        }
        if (this.isPresentEquipmentManagementAuth(btn.authId)) {
          const buttonSTSort = response.data.find(
            (el) => el.authId === "EQPMNG"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonEQPMNG)) {
            this.buttonEQPMNG.sort = buttonSTSort;
            btnList.push(this.buttonEQPMNG);
          }
        }
      }
      this.reOrderButtonsHome(btnList);
    } catch (e) {
      snackbarModule.appearSnackbar({
        message: this.$tc("home.snackOrderNotLoaded"),
        type: "info",
      });
      this.buttonListEnabled = this.buttonList;
    }

    overlayModule.hideOverlay();
  }

  async submitEdit(): Promise<void> {
    overlayModule.showOverlay();

    let submitArr: InsertItem[] = [];
    this.buttonListEnabled.forEach((btn, i) => {
      submitArr.push({
        userId: authModule.userID,
        authId: btn.id,
        sort: i + 1,
      });
    });

    try {
      await buttonSortService.insertSorts(submitArr);
      snackbarModule.appearSnackbar({
        message: this.$tc("home.snackOrderChangeSuccess"),
        type: "success",
      });
    } catch (e) {
      snackbarModule.appearSnackbar({
        message: this.$tc("home.snackOrderChangeFailure"),
        type: "warning",
      });
    }

    overlayModule.hideOverlay();
    this.reloadButtonOrder();
  }

  reOrderButtonsHome(btnList: HomeButton[]): void {
    btnList.sort((a, b) => a.sort - b.sort);
    this.buttonListEnabled = btnList;
  }

  submitLogout(): void {
    this.isOrderButtonsOn = false;
    authModule.logout();
    this.$router.push({ path: "/login" });
  }

  changeOrderButtonAction(): void {
    this.isOrderButtonsOn = !this.isOrderButtonsOn;

    if (!this.isOrderButtonsOn) this.submitEdit();
  }

  async restoreButtonSort(): Promise<void> {
    overlayModule.showOverlay();
    await buttonSortService.restoreSort(
      this.buttonListEnabled.map((el) => el.id)
    );
    overlayModule.hideOverlay();

    await this.reloadButtonOrder();
    snackbarModule.appearSnackbar({
      message: this.$tc("home.OrderButtonSortRestored"),
      type: "success",
    });
  }

  goChangePassword() {
    this.$router.push("ChangePassword");
  }
}
</script>
<style scoped>
.button-grid-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.button-grid-vertical {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-item {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.grid-item-text {
  font-weight: bold;
  text-align: center;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{attrs:{"id":"app"}},[(
      _vm.pageName != 'login' &&
      _vm.pageName != 'equipmentDetails' &&
      _vm.pageName != 'PdfOpener'
    )?_c('v-app-bar',{staticClass:"mod--shadow_appbar",attrs:{"color":"white","app":"","height":105}},[_c('v-col',{staticStyle:{"padding":"0"}},[_c('v-row',{staticStyle:{"margin":"0","align-items":"center","width":"100%"}},[_c('div',{staticClass:"px-0",staticStyle:{"display":"flex"}},[_c('v-img',{staticClass:"shrink mx-auto",attrs:{"alt":_vm.$tc('alt.logoDigita'),"contain":"","src":require('../src/assets/msa-digita-logo.png'),"transition":"scale-transition","width":"250px","max-width":"250px"}})],1),(_vm.isProductionTest)?_c('span',{staticStyle:{"font-size":"42px","color":"red"}},[_vm._v(" TEST ")]):_vm._e(),_c('v-img',{attrs:{"src":require('@/assets/PRIMO SOCCORSO.png'),"max-height":"115px","max-width":"115px"},on:{"click":function($event){return _vm.openFile('SECCHART')}}}),_c('v-img',{attrs:{"src":require('@/assets/REFERENTI EMERGENZE.png'),"max-height":"115px","max-width":"115px"},on:{"click":function($event){return _vm.openFile('EMERGENCY_CONTACTS')}}}),_c('v-img',{attrs:{"src":require('@/assets/calcolatrice.png'),"max-height":"115px","max-width":"115px"},on:{"click":function($event){_vm.showCalculator = true}}}),_c('v-spacer'),_c('span',{staticClass:"ma-0 px-0 text-right mod--header"},[_vm._v(" "+_vm._s(_vm.isOrientationPortrait ? null : _vm.dataString)+"    "+_vm._s(_vm.dataString2)+" ")])],1)],1)],1):_vm._e(),_c('v-main',{class:{
      'main-content-home': _vm.pageName === 'home',
      classByPageName: _vm.classByPageName,
      themeColor: _vm.themeColor,
    }},[_c('router-view')],1),(_vm.showCalculator)?_c('calculator',{on:{"click:outside":function($event){_vm.showCalculator = false}},model:{value:(_vm.showCalculator),callback:function ($$v) {_vm.showCalculator=$$v},expression:"showCalculator"}}):_vm._e(),_c('v-snackbar',{staticClass:"message-snackbar mod--snackbar",attrs:{"timeout":-1,"value":_vm.snackbar,"bottom":"","right":"","elevation":"24","color":'var(--v-' + _vm.type + '-lighten2)'}},[_c('div',{staticClass:"d-flex"},[(_vm.icon)?_c('v-icon',{staticClass:"mod--icon mr-2",attrs:{"color":_vm.type + ' darken2',"size":38}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c('span',{class:_vm.type + '--text'},[_vm._v(_vm._s(_vm.message))])],1)]),_c('v-overlay',{attrs:{"value":_vm.overlayVisible,"z-index":"1000"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.pageName == 'login')?_c('v-footer',{staticClass:"justify-center",attrs:{"flat":"","color":"white"}},[_c('span',[_vm._v(_vm._s(_vm.$tc("general.footerText", 1, { year: new Date().getFullYear() })))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
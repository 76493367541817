<template>
  <div>
    <!-- TODO: PIÙ AVANTI SARÀ DA DIVIDERE LA VISUALIZZAZIONE DESKTOP DA tablet, per ora è ottimizzata per tablet-->
    <!--    <lg-small-maintenance v-if="isDesktop"></lg-small-maintenance>-->
    <lg-small-maintenance></lg-small-maintenance>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

import LgSmallMaintenance from "@/views/Maintenance/lg/LgSmallMaintenance.vue";
import XsSmallMaintenance from "@/views/Maintenance/xs/XsSmallMaintenance.vue";

@Component({
  name: "SmallMaintenance",
  components: { XsSmallMaintenance, LgSmallMaintenance },
})
export default class SmallMaintenance extends Vue {}
</script>
<style></style>

<template>
  <div class="page-container">
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('mansap.Title')"
        :is-parent-element="false"
        forced-previous-component="Training"
      ></title-bar>
    </v-col>

    <v-data-table
      v-if="tableItems.length"
      :headers="headers"
      :items="tableItems"
      class="data-table header-bold"
      disable-pagination
      hide-default-footer
      @click:row="downloadFile"
    >
      <template v-slot:[`item.value`]="{ item }">
        <td class="first-column">
          {{ item.value }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <td class="second-column">
          <v-btn text v-if="item" class="mod--btn_text">
            <v-icon left color="primary" class="ml-1" :size="38">
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { manSapService } from "@/services/api/manSap.service";
import { DataTableHeader } from "vuetify";

@Component({})
export default class Mansap extends Vue {
  fileNames: string[] = [];
  headers: DataTableHeader[] = [
    {
      text: this.$tc("mansap.Document"),
      align: "start",
      sortable: false,
      value: "value",
      class: "fixed-column",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  get tableItems(): { value: string }[] {
    return this.fileNames.map((x) => ({
      value: x,
    }));
  }

  created(): void {
    this.loadManSap();
  }

  async loadManSap(): Promise<void> {
    const response = await manSapService.getFileNames();

    this.fileNames = response.data;
  }

  downloadFile(fileClicked: { value: string }): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { type: "MANSAP", fileName: fileClicked.value },
    });
    window.open(routeData.href, "_blank");
    return;
  }
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
}

.data-table {
  width: 100%;
  margin-top: 12px;
  table-layout: fixed;
}

.first-column {
  max-width: 200px !important;
  white-space: normal;
  overflow-wrap: break-word;
}

.second-column {
  width: 100px;
}
</style>

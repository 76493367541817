import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { FileLogItem } from "@/models/entities/fileFormats/fileLogs.interface";
import { AssetBaseInterface } from "@/models/entities/maintenance/assetBase.interface";
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { RequestManualFileInterface } from "@/models/entities/maintenance/requestManualFile.interface";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";
import {
  AssetsByDepartmentRequest,
  CheckAllowPlanCompilationRequest,
  SaveSmallMaintenanceAnswerRequest,
  SaveSmallMaintenanceNotesRequest,
  SaveSmallMaintenanceOutcomeRequest,
  SmallMaintenancePlanRequest,
} from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import {
  SmallMaintenanceAnswerTypes,
  SmallMaintenancePlan,
  SmallMaintenanceRowBase,
} from "@/models/entities/maintenance/smallMaintenancePlan.interface";
import {
  HandoverLog,
  SaveOrUpdateHandoverNotesRequest,
} from "@/models/entities/Handover/handover.interface";

const CONTROLLER = "handover";
class HandoverService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getDepartmentsByUser() {
    return this.getAll<DepartmentItemInterface[]>(`${CONTROLLER}/departments`);
  }

  getHandoverLog(departmentId: string, location?: string) {
    const params = {
      departmentId: departmentId,
      location: location,
    };
    return this.getAll<HandoverLog[]>(`${CONTROLLER}/log`, {
      params: params,
    });
  }

  modifyNotes(request: SaveOrUpdateHandoverNotesRequest) {
    return this.post<SaveOrUpdateHandoverNotesRequest, void>(
      `${CONTROLLER}/modifynotes`,
      request
    );
  }
}

export const handoverService = new HandoverService();

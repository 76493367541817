import { AuthItem } from "@/models/entities/auth/authItem.interface";
import { LoginResponse } from "@/models/entities/auth/loginResponse.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "auth" })
class AuthModule extends VuexModule {
  private authState: LoginResponse = null;
  private enabledSectionsList: AuthItem[] = [];
  private _currentAuthId = "";
  private _isOrientationPortrait = false; //indica se il tablet è in verticale -> isPortrait = verticale

  get userID(): number {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userID
        : -1
      : -1;
  }

  get isOrientationPortrait(): boolean {
    return this._isOrientationPortrait;
  }

  get userName(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userName
        : ""
      : "";
  }

  get userDescription(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userDescription
        : ""
      : "";
  }

  get isEnabled(): boolean {
    return this.authState
      ? this.authState.user
        ? this.authState.user.isEnabled
        : false
      : false;
  }

  get userGroupId(): number {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userGroupId
        : -1
      : -1;
  }

  get token(): boolean {
    return this.authState ? this.authState.token : false;
  }

  get sectionsList(): AuthItem[] {
    return this.enabledSectionsList;
  }

  @Mutation
  public setLogin(state: LoginResponse): void {
    this.authState = state;
  }

  @Action
  public login(state: LoginResponse): void {
    this.context.commit("setLogin", state);
  }

  @Mutation
  public setLogout(): void {
    this.authState = null;
    this.enabledSectionsList = [];
  }
  @Action
  public logout(): void {
    this.context.commit("setLogout");
  }

  @Mutation
  public setEnabledSections(state: AuthItem[]): void {
    this.enabledSectionsList = state;
  }

  @Action
  public enabledSections(state: AuthItem[]): void {
    this.context.commit("setEnabledSections", state);
  }

  @Mutation
  private _setCurrentAuthId(authId: string): void {
    this._currentAuthId = authId;
  }

  @Action
  public setCurrentAuthId(authId: string): void {
    this.context.commit("_setCurrentAuthId", authId);
  }

  @Mutation
  private _setOrientationPortrait(value: boolean): void {
    this._isOrientationPortrait = value;
  }

  @Action
  public setOrientationPortrait(value: boolean): void {
    this.context.commit("_setOrientationPortrait", value);
  }
}

export default AuthModule;

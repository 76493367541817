<template>
  <v-btn
    @click="toggleSpeech"
    class="microphone-btn"
    width="48px"
    height="48px"
    min-width="0"
    :class="{ listening: isListening }"
  >
    <v-icon>
      {{ isListening ? "mdi-microphone" : "mdi-microphone-off" }}
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
import { Vue, Component, Model, Watch, PropSync } from "vue-property-decorator";
import { useSpeechRecognition } from "@vueuse/core";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class SpeechToText extends Vue {
  @PropSync("notes")
  notesLocal: string;

  speechRecognition = useSpeechRecognition({
    lang: "it-IT",
    interimResults: false,
  });

  @Watch("transcript")
  onTranscriptChange() {
    if (this.transcript.trim()) {
      this.notesLocal = this.notesLocal
        ? `${this.notesLocal} ${this.transcript.trim()}`
        : this.transcript.trim();
    }
  }

  toggleSpeech() {
    if (!this.isSupported) {
      snackbarModule.appearSnackbar({
        message: this.$tc("speechToText.NotSupported"),
        type: "error",
      });
      return;
    }
    setTimeout(() => {
      if (this.isListening) this.speechRecognition.stop();
      else this.speechRecognition.start();
    }, 0);
  }

  get isSupported() {
    return this.speechRecognition.isSupported;
  }

  get isListening(): boolean {
    return this.speechRecognition.isListening;
  }

  get transcript() {
    return this.speechRecognition.result;
  }
}
</script>

<style scoped>
.microphone-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5f5f5;
  transition: background-color 0.3s;
}

.microphone-btn.listening {
  background-color: #007bc1;
  animation: pulse 1.5s infinite;
  color: white;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 193, 0.5); /* Blu chiaro */
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 193, 0); /* Dissolvenza */
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 193, 0); /* Scompare */
  }
}
</style>
